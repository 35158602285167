export const share = (url, title, description) => {
  let shareLink;
  if (url.includes("http") || url.includes("https")) {
    shareLink = url;
  } else {
    if (url[0] === "/") shareLink = window.location.origin + url;
    else shareLink = window.location.origin + window.location.pathname + "/" + url;
  }
  if (navigator.share) {
    navigator
      .share({
        title: title,
        text: description,
        url: shareLink,
      })
      .catch((error) => console.log("Retry again"));
  } else {
    alert("Web Share not supported. You can manually share the page.");
  }
};
