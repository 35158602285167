import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import { GradientButton, OutlinedButton } from "components/button/buttons";

const CallForAction = () => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, text, error, secondary } = palette;
  const { pxToRem } = functions;
  return (
    <Box
      sx={{
        position: "relative",
        marginTop: "3rem"
      }}
    >
      <Box
        sx={{
          position: "absolute",
          height: "100%",
          width: "100%",
          zIndex: "2",
          background: `linear-gradient(to top, #efe4ff85 50%, #00000000)`,
        }}
      ></Box>
      <Box
        sx={{
          borderLeft: "calc(100vw - 20px) solid white",
          borderBottom: "100px solid transparent",
          position: "relative",
          zIndex: "3",
        }}
      ></Box>
      <video
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "absolute",
          top: 0,
        }}
        src="/videos/bgvideo.webm"
        autoPlay
        muted
        loop
      />

      <Box
        sx={{
          padding: "3rem",
          position: "relative",
          zIndex: 3,
        }}
      >
        <Box
          sx={{
            fontSize: "2.5rem",
            letterSpacing: "-1px",
            fontWeight: "500",
            maxWidth: "500px",
            margin: "auto",
            textAlign: "center",
            marginBottom: "2rem"
          }}
          className={"quicksand heading"}
        >
          Ready to transform your supply chain?
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "1rem",
            "@media (max-width: 500px)": {
              flexDirection: "column",
            },
          }}
        >
          <GradientButton
            name={"Request a Demo"}
            sx={{
              padding: ".5rem 2rem",
            }}
            endIcon={
              <span
                class="material-symbols-outlined"
                style={{ color: white.main }}
              >
                east
              </span>
            }
            data-tally-open="mOAaPK"
          />
          {/* <OutlinedButton
            name={"Contact Us"}
            sx={{
              padding: ".5rem 2rem",
            }}
            data-tally-open="mOAaPK"
          /> */}
        </Box>
      </Box>
      <Box
        sx={{
          borderRight: "calc(100vw - 20px) solid white",
          borderTop: "100px solid transparent",
          position: "relative",
          zIndex: "5",
        }}
      ></Box>
    </Box>
  );
};

export default CallForAction;
