// @mui material components
import { createTheme } from "@mui/material/styles";

//  React base styles
import borders from "assets/theme/base/borders";
import breakpoints from "assets/theme/base/breakpoints";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";

//  React helper functions
import boxShadow from "assets/theme/functions/boxShadow";
import hexToRgb from "assets/theme/functions/hexToRgb";
import linearGradient from "assets/theme/functions/linearGradient";
import pxToRem from "assets/theme/functions/pxToRem";
import rgba from "assets/theme/functions/rgba";

export default createTheme({
  breakpoints: { ...breakpoints },
  palette: { ...colors },
  typography: { ...typography },
  borders: { ...borders },
  functions: {
    boxShadow,
    hexToRgb,
    linearGradient,
    pxToRem,
    rgba,
  },
});
