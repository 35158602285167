import { Box, useTheme } from "@mui/material";
import Hero from "./components/Hero";
import VideoSection from "./components/VideoSection";
import ImageSection from "./components/ImageSection";
import InfoSection from "./components/InfoSection";
import Testimonials from "./components/Testimonials";
import CallForAction from "./components/CallForAction";
import Footer from "./components/Footer";
import WhyNeatProcess from "./components/WhyNeatProcess";

function Home() {
  const { palette, functions, typography } = useTheme();
  const { primary, white, text, error, secondary } = palette;
  const { pxToRem } = functions;

  return (
    <Box sx={{ backgroundColor: white.main }}>
      <Hero />
      <ImageSection />
      <VideoSection />
      <WhyNeatProcess/>
      <InfoSection />
      <Testimonials />
      <CallForAction />
      <Footer />
    </Box>
  );
}

export default Home;
