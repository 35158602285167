import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import cardBg from "assets/images/backgrounds/hero-bg.png";

export const ImageBackground = ({ children }) => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, text, error, secondary } = palette;
  const { pxToRem } = functions;

  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: "#ffffff",
        backgroundImage: `url(${cardBg})`,
        backgroundSize: "auto",
        backgroundPosition: "center",
        backgroundRepeat: "repeat",
        minHeight: "100vh",
        height: "auto",
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: "#ffffffcf",
          position: "absolute",
          zIndex: 1,
        }}
      ></Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "relative",
          zIndex: 2,
          minHeight: "100vh"
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
