import BlogLayout from "layouts/blog/components/BlogLayout";
import image from "assets/images/blog/docStore/image.png"
import image1 from "assets/images/blog/docStore/image 1.png"
import image2 from "assets/images/blog/docStore/image 2.png"

const DocStore = () => {
  return (
    <BlogLayout>
      <article id="15278088-1a7e-80a2-8357-cb13b3dfec12" class="page sans">
        <header>
          <h1 class="page-title"><strong>DocStore</strong></h1>
          <p class="page-description"></p>
        </header>
        <div class="page-body">
          <blockquote id="15278088-1a7e-80b1-bade-d41c8e736ae0" class="">
            <em><strong>Streamlining Document Management</strong></em>
          </blockquote>
          <p id="15278088-1a7e-8006-a723-c6c62222bca0" class="">Organizations often face significant challenges managing their documents.</p>
          <p id="15278088-1a7e-8022-b65e-dc8a4ce26f56" class="">Files are <strong>scattered across multiple systems, tools, and formats</strong>, making it difficult to locate or share them efficiently.</p>
          <p id="15278088-1a7e-80dd-983a-dc8fbfd4ca97" class="">This leads to <strong>wasted time</strong>, <strong>decreased productivity,</strong> and <strong>frequent errors</strong> in document handling.</p>
          <p id="15278088-1a7e-8093-8047-dd754dc0c52c" class="">Additionally, ensuring secure and structured sharing of documents within hierarchical teams adds another layer of complexity, especially for large organizations.</p>
          <hr id="15278088-1a7e-8017-84fd-cc3e2bf0082b"/>
          <h2 id="15278088-1a7e-804b-aa7c-ffac74ec5776" class="">AI powered Search</h2>
          <p id="15278088-1a7e-804b-9a07-f123edf77390" class="">DocStore's powerful search engine leverages AI and natural language processing (NLP) to enable instant document retrieval.</p>
          <p id="15278088-1a7e-80ef-a9c4-ddbadb394a35" class="">Users can search by <strong>keywords, file context, or metadata,</strong> ensuring accuracy and efficiency.</p>
          <p id="15278088-1a7e-8032-aff5-f9caf8db5d31" class=""></p>
          <figure id="15278088-1a7e-8057-a57e-c68fff91ad53" class="image">
            <a href="image.png"><img style={{width:"100%"}} src={image} alt="image"/></a>
          </figure>
          <p id="15278088-1a7e-80da-aed2-fcf27578ddf1" class=""></p>
          <p id="15278088-1a7e-808d-9686-d433f141076e" class="">This advanced search functionality <strong>reduces the time spent searching for documents by 75%</strong>, allowing teams to focus on core tasks without being bogged down by disorganized files.</p>
          <hr id="15278088-1a7e-80ec-bae0-c342e30c1209"/>
          <h2 id="15278088-1a7e-80a0-ab91-d2dd797af77a" class=""><strong>Integrations</strong></h2>
          <p id="15278088-1a7e-8066-a96f-fb08631386ba" class="">DocStore integrates seamlessly with popular tools like <strong>ERP systems, Emails, Manual-uploads and transporter platforms</strong> centralizing document collection in one unified platform.</p>
          <p id="15278088-1a7e-801e-b384-d643f29ff19d" class="">DocStore seamlessley <strong>connects and communicates with the power of AI</strong> with other Neatprocess’ tools like ClaimDesk and DeliveryDesk.</p>
          <p id="15278088-1a7e-80fb-8058-f11b52ad04cb" class=""></p>
          <figure id="15278088-1a7e-8091-826c-e15c66e9ce49" class="image">
            <a href="image%201.png"><img style={{width:"100%"}} src={image1} alt="image%201"/></a>
          </figure>
          <p id="15278088-1a7e-8094-bcf2-cceb42dd5053" class=""></p>
          <p id="15278088-1a7e-807a-b680-df82b7a164fd" class="">By automating updates and eliminating the need for manual efforts, DocStore <strong>improves document accessibility by 60%</strong>, ensuring that critical files are always available and up to date.</p>
          <hr id="15278088-1a7e-80ad-8e02-f5f2f6fbc8da"/>
          <h2 id="15278088-1a7e-808e-bc05-e5254ff566b5" class=""><strong>Sharing - Organizational Structure</strong></h2>
          <p id="15278088-1a7e-8099-8759-c856010bce53" class="">DocStore’s team-based sharing model is designed to align with your organization's hierarchy.</p>
          <p id="15278088-1a7e-80c3-933c-f5c4f90cfe42" class="">Permissions propagate automatically across parent and sub-teams, removing the hassle of manual configuration while maintaining data security.</p>
          <p id="15278088-1a7e-80db-b840-fad835b440dc" class=""></p>
          <figure id="15278088-1a7e-805b-9c4b-c8093becb88a" class="image">
            <a href="image%202.png"><img style={{width:"100%"}} src={image2} alt="image%202"/></a>
          </figure>
          <p id="15278088-1a7e-80c0-a562-f487bc4eb61a" class=""></p>
          <p id="15278088-1a7e-8032-80fb-cb1eb28c406f" class="">This approach simplifies collaboration and reduces data access mismanagement by <strong>90%</strong>, enabling teams to work together seamlessly and securely.</p>
          <hr id="15278088-1a7e-802f-b155-df7bb6489b6e"/>
          <h2 id="15278088-1a7e-80ea-a112-db33536fa41a" class=""><strong>Conclusion</strong></h2>
          <p id="15278088-1a7e-80d7-acdf-d0ed968c0404" class="">DocStore is more than just a document management system; it’s a tool for organizational transformation.</p>
          <p id="15278088-1a7e-80a1-abde-e87e4f8e48ad" class="">By streamlining search, integration, and sharing, it eliminates inefficiencies and enhances collaboration across teams.</p>
          <p id="15278088-1a7e-8037-95d1-e25cd196329e" class="">With DocStore, businesses can break free from document chaos, boost productivity, and focus on driving innovation and growth.</p>
          <p id="15278088-1a7e-81a1-a32c-c801c83ce83d" class=""></p>
        </div>
      </article>  
    </BlogLayout>
  );
};

export default DocStore;
