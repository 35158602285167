import { Box } from "@mui/material";
import image1 from "assets/images/hero/iconsset.png";
import image2 from "assets/images/hero/central-image.png";
import image3 from "assets/images/hero/result.png";
import arrow1 from "assets/images/hero/arrow1.svg";
import arrow2 from "assets/images/hero/arrow2.svg";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.compat.css";
import { sparkleSvg } from "components/svgIcons/svgIcons";
import { useTheme } from "@emotion/react";

const ImageSection = () => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, text, error, secondary } = palette;
  const images = [
    {
      src: image1,
      style: {
        zIndex: "1",
        height: "310px",
        left: "40px",
        "@media (max-width: 1100px)": {
          height: "calc(310px * .7)",
        },
        "@media (max-width: 800px)": {
          height: "calc(310px * .5)",
        },
        "@media (max-width: 600px)": {
          height: "calc(310px * .3)",
        },
      },
    },
    {
      src: arrow1,
      style: {
        zIndex: "0",
        height: "194px",
        left: "10px",
        "@media (max-width: 1100px)": {
          height: "calc(194px * .7)",
        },
        "@media (max-width: 800px)": {
          height: "calc(194px * .5)",
        },
        "@media (max-width: 600px)": {
          height: "calc(194px * .3)",
        },
      },
    },
    {
      src: image2,
      style: {
        zIndex: "0",
        height: "260px",
        top: "20px",
        "@media (max-width: 1100px)": {
          height: "calc(260px * .7)",
        },
        "@media (max-width: 800px)": {
          height: "calc(260px * .5)",
        },
        "@media (max-width: 600px)": {
          height: "calc(260px * .3)",
        },
      },
    },
    {
      src: arrow2,

      style: {
        zIndex: "1",
        height: "215px",
        "@media (max-width: 1100px)": {
          height: "calc(215px * .7)",
        },
        "@media (max-width: 800px)": {
          height: "calc(215px * .5)",
        },
        "@media (max-width: 600px)": {
          height: "calc(215px * .3)",
        },
      },
    },
    {
      src: image3,
      style: {
        zIndex: "0",
        height: "275px",
        right: "10px",
        "@media (max-width: 1100px)": {
          height: "calc(275px * .7)",
        },
        "@media (max-width: 800px)": {
          height: "calc(275px * .5)",
        },
        "@media (max-width: 600px)": {
          height: "calc(275px * .3)",
        },
      },
    },
  ];
  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          padding: "2rem",
          fontSize: "2.5rem",
          position: "relative",
          letterSpacing: "-1px",
          fontWeight: "500",
        }}
        className={"quicksand heading"}
      >
        What we do?
        <span
          style={{
            fontSize: "2rem",
            position: "relative",
            top: "1rem",
            marginleft: "10px",
          }}
        >
          {sparkleSvg(primary.main)}
        </span>
      </Box>
      <Box
        sx={{
          marginX: "1rem",
          borderRadius: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          paddingY: "2rem",
        }}
      >
        {images.map((image, index) => (
          <ScrollAnimation
            animateIn="fadeIn"
            delay={50 * index}
            style={{
              position: "relative",
              zIndex: image.style.zIndex,
            }}
          >
            <Box
              component={"img"}
              key={index}
              src={image.src}
              sx={{
                position: "relative",
                ...image.style,
              }}
            />
          </ScrollAnimation>
        ))}
      </Box>
    </>
  );
};

export default ImageSection;
