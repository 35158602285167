import { useTheme } from "@emotion/react";
import { sparkleSvg } from "components/svgIcons/svgIcons";
import ScrollAnimation from "react-animate-on-scroll";
import dataDriven from "assets/images/svg/data-driven.svg";
import search from "assets/images/svg/search-visible.svg";
import setting from "assets/images/svg/accelerated.svg";

const { Box } = require("@mui/material");

const InfoSection = () => {
  const theme = useTheme();
  const data = [
    {
      title: "Data Driven Decisions",
      content:
        "Use real-time insights to improve delivery performance and optimize resources",
      icon: <img src={dataDriven} height={"40px"} />,
    },
    {
      title: "Enhanced Visibility & Control",
      content: "Gain complete visibility of your logistics data and documents.",
      icon: <img src={search} height={"40px"} />,
    },
    {
      title: "Accelerated Logistic claims Processing",
      content: "Reduce manual work and speed up claim resolution.",
      icon: <img src={setting} height={"40px"} />,
    },
  ];
  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          margin: "5rem",
          fontSize: "2.5rem",
          position: "relative",
          letterSpacing: "-1px",
          fontWeight: "500",
          "@media (max-width: 900px)": {
            margin: "1rem",
          },
        }}
        className={"quicksand heading"}
      >
        <span
          style={{
            fontSize: "2rem",
            position: "relative",
            top: "-1rem",
            marginRight: "5px",
          }}
        >
          {sparkleSvg(theme.palette.primary.main)}
        </span>
        Delivering Value at Every Step
      </Box>
      <Box
        sx={{
          display: "grid",
          gap: "1rem",
          justifyContent: "center",
          alignItems: "center",
          padding: "5rem",
          gridTemplateColumns: `repeat(3, 1fr)`,
          background: `linear-gradient(to top, #D6D3FF, #00000000 )`,
          borderRadius: "20px",
          margin: "auto",
          marginBottom: '1rem',
          maxWidth: "1200px",
          overflow: "hidden",
          "@media (max-width: 1200px)": {
            marginX: "2rem",
            padding: '3rem',
          },
          "@media (max-width: 700px)": {
            padding: '2rem',
            gridTemplateColumns: `repeat(1, 1fr)`,
          },
        }}
      >
        {data.map((info, index) => (
          <ScrollAnimation
            key={index}
            animateIn="slideInUp"
            delay={100 * index}
            style={{ height: "100%" }}
          >
            <InfoCards {...info} />
          </ScrollAnimation>
        ))}
      </Box>
    </>
  );
};

export default InfoSection;

const InfoCards = ({ title, content, icon }) => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, text, error, secondary } = palette;
  const { pxToRem } = functions;
  return (
    <Box
      sx={{
        maxWidth: "300px",
        display: "flex",
        flexDirection: "column",
        color: text.main,
        backgroundColor: white.main,
        justifyContent: "center",
        alignItems: "center",
        gap: "2rem",
        border: "1px solid " + primary.main,
        borderRadius: "50px",
        padding: "1.5rem",
        boxShadow: "0px 2px 14px #6a6bff4d",
        height: "100%",
        margin: "auto"
      }}
    >
      {icon}
      <Box
        sx={{
          width: "70%",
          fontWeight: "bold",
          fontSize: typography.size["xl"],
          lineHeight: "1.3em",
          textAlign: "center",
          letterSpacing: "-1px",
        }}
        className={"quicksand"}
      >
        {title}
      </Box>

      <Box
        sx={{
          fontSize: typography.size["sm"],
          lineHeight: "1.3em",
          textAlign: "center",
        }}
      >
        {content}
      </Box>
    </Box>
  );
};
