import BlogLayout from "layouts/blog/components/BlogLayout";
import image from "assets/images/blog/deliveryDesk/image.png"
import image1 from "assets/images/blog/deliveryDesk/image 1.png"
import image2 from "assets/images/blog/deliveryDesk/image 2.png"

const DeliveryDesk = () => {
  return (
    <BlogLayout>
       <article id="15278088-1a7e-807d-a651-cb45c5b4671b" class="page sans">
        <header>
          <h1 class="page-title">DeliveryDesk:</h1>
          <p class="page-description"></p>
        </header>
        <div class="page-body">
          <blockquote id="15278088-1a7e-8033-b641-c470f9c8cd6f" class="">
            <em><strong>Streamlining Delivery Management</strong></em>
          </blockquote>
          <p id="15278088-1a7e-8043-be8c-f4e87160070e" class="">Managing deliveries efficiently is a complex task.</p>
          <p id="15278088-1a7e-8064-b57e-eaadcbe6e2a3" class="">Delivery information and documents are often <strong>scattered across multiple systems, leading to confusion, delays, and increased compliance and payment turnaround times (TATs)</strong>.</p>
          <p id="15278088-1a7e-8006-84b8-f5e0491fd397" class="">Additionally, fragmented systems make it <strong>challenging to synchronize delivery data</strong>, and the <strong>lack of actionable insights</strong> into key metrics hampers decision-making and performance tracking.</p>
          <h2 id="15278088-1a7e-80ca-943b-e9e85a9c3936" class="">Delivery Details</h2>
          <p id="15278088-1a7e-80aa-b357-dc1711df627b" class="">DeliveryDesk centralizes all delivery details and documents into a single, unified platform.</p>
          <p id="15278088-1a7e-80c6-92c2-f24886dff8be" class="">By consolidating data from various sources, teams gain instant access to accurate and up-to-date information.</p>
          <p id="15278088-1a7e-80f5-9acb-df1b973dfa9d" class=""></p>
          <figure id="15278088-1a7e-80cf-9459-f8ec772da022" class="image">
            <a href="image.png"><img style={{width: '100%'}} src={image} alt="image"/></a>
          </figure>
          <p id="15278088-1a7e-8007-9be5-c5f65ab2123e" class="">This feature minimizes confusion, shortens compliance and payment TATs, and ensures that everyone is aligned for smoother delivery processes.</p>
          <p id="15278088-1a7e-8098-80af-c7b6f56e4c14" class=""></p>
          <h2 id="15278088-1a7e-801b-aff5-deabd21fdbd9" class=""><strong>Integration</strong></h2>
          <p id="15278088-1a7e-800d-b119-c5561b32dd43" class="">Seamlessly integrating with <strong>ERP systems, Emails, Manual-uploads, transporter platforms, and warehouse management tools</strong>, DeliveryDesk centralizes and synchronizes delivery data automatically.</p>
          <p id="15278088-1a7e-8006-8010-d0094dac6b52" class=""></p>
          <figure id="15278088-1a7e-807b-afe2-f258368722fa" class="image">
            <a href="image%201.png"><img style={{width: '100%'}} src={image1} alt="image%201"/></a>
          </figure>
          <p id="15278088-1a7e-8085-8342-ee58c6c80d4c" class=""></p>
          <p id="15278088-1a7e-802d-9f55-cd1eebfc44c3" class="">This integration reduces manual data entry errors by <strong>80%</strong> and provides real-time updates for improved coordination and faster decision-making across teams and stakeholders.</p>
          <p id="15278088-1a7e-8022-aeaf-da6f9cfabeb8" class=""></p>
          <h2 id="15278088-1a7e-8069-a467-f8b035e3543f" class="">Analytics</h2>
          <p id="15278088-1a7e-8051-b2fb-e05b496cb6e4" class="">DeliveryDesk offers a powerful dashboard with real-time analytics, enabling businesses to track different transport metrics.</p>
          <p id="15278088-1a7e-804d-84a8-e1d41f9578e3" class="">By reducing the manual effort of aggregating and calculating transport metrics, the dashboard provides actionable insights that help teams identify bottlenecks and optimize logistics performance.</p>
          <p id="15278088-1a7e-80f9-8f8f-e8be46ae6581" class=""></p>
          <figure id="15278088-1a7e-80f8-9c5a-e3d08b31f78b" class="image">
            <a href="image%202.png"><img style={{width: '100%'}} src={image2} alt="image%202"/></a>
          </figure>
          <h2 id="15278088-1a7e-809a-8b87-d130e2dfb131" class=""><strong>Conclusion</strong></h2>
          <p id="15278088-1a7e-80c6-9f86-f251ee1b84b5" class="">DeliveryDesk is the go-to solution for modern delivery management.</p>
          <p id="15278088-1a7e-804b-a48b-cd67233319c3" class="">With features that consolidate delivery details, integrate seamlessly with existing systems, and provide real-time analytics, it empowers businesses to enhance coordination, reduce costs, and ensure timely deliveries.</p>
          <p id="15278088-1a7e-80ca-b68f-f62721556536" class="">DeliveryDesk transforms delivery operations into a streamlined, efficient process, enabling teams to focus on delivering value.</p>
          <p id="15278088-1a7e-807f-9281-fac8354dddad" class=""></p>
        </div>
      </article>
    </BlogLayout>
  );
};

export default DeliveryDesk;
