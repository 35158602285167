import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import NavBar from "components/navbar/NavBar";
import { Link } from "react-router-dom";
import { ImageBackground } from "components/background/background";
import Footer from "layouts/home/components/Footer";

const PrivacyPolicy = () => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, text, error, secondary } = palette;
  const { pxToRem } = functions;
  return (
    <ImageBackground>
      <NavBar
        links={
          <>
            <Link to="/terms/privacy-policy">Privacy policy</Link>
            <Link to="/terms/security-policy">Security policy</Link>
            <Link to="/terms/gdpr-policy">GDPR</Link>
          </>
        }
      />
      <Box
        sx={{
          borderRadius: "20px",
          backgroundColor: white.main,
          padding: "2rem 4rem",
          width: "79%",
          margin: "auto",
          maxWidth: "990px",
          marginY: "2rem",
          boxShadow: "0 4px 14px 0 #6A6BFF99",
          "@media (max-width: 700px)": {
            width: "89%",
            padding: "2rem",
          },
          "@media (max-width: 500px)": {
            width: "89%",
            padding: "1rem",
          },
        }}
      >
        <article id="bd51cc4f-6df9-4032-96a0-854c5b89a8be" class="page sans">
          <header>
            <h1 class="page-title">
              <strong>Privacy Policy</strong>
            </h1>
            <p class="page-description"></p>
          </header>
          <div class="page-body">
            <p id="b0230ee6-f2e4-480d-98b7-708179c3d8a7" class="">
              It covers every Neatprocess owned website that links here, and all
              of the products and services contained on those websites. The
              detailed policy follows the same structure as this summary and
              constitutes the actual legal document.
            </p>
            <p id="d321f6f6-1c53-4319-9f05-aa9cdecd36ce" class="">
              Our privacy commitment: Neatprocess has never sold your
              information to someone else for advertising, or made money by
              showing you other people's ads, and we never will. This has been
              our approach, and we remain committed to it. This policy tells you
              what information we collect from you, what we do with it, who can
              access it, and what you can do about it.
            </p>
            <h2 id="629cfeb5-3b12-48ba-91f2-c0521b400d79" class="">
              <strong>Part I</strong>
            </h2>
            <h3 id="30bc3514-ef6c-4732-9d13-bd5414929ccc" class="">
              <strong>Information Neatprocess collects and controls</strong>
            </h3>
            <p id="9a9cc79d-6939-448c-a7b2-e3181323b1ca" class="">
              Neatprocess provides software-as-a-service platforms designed to
              offer employees of our enterprise customers to serve their supply
              chain automation needs.
            </p>
            <p id="2d721c12-9bb6-43c6-8a11-b7e7dd0aadbf" class="">
              We only collect the information that we actually need. Our
              websites, products, and services are primarily designed for
              enterprise organizations and their representatives. We do not
              offer products or services for use by individuals for their
              personal, family, or household purposes. Accordingly, we treat all
              personal information we collect as pertaining to individuals in
              their capacities as enterprise organization representatives and
              not their individual capacities.
            </p>
            <p id="11fd7bc0-1491-43e8-aa36-fbca27c67cbf" class="">
              When you visit one of our websites or use our software, we
              automatically log some basic information like how you got to the
              site, where you navigated within it, and what features and
              settings you use. We use this information to improve our websites
              and services and to drive new product development.
            </p>
            <h3 id="12906d7b-5e9b-42b9-96a4-9e3a2a3d478e" class="">
              <strong>What we do with your information</strong>
            </h3>
            <p id="8997de61-463b-4134-8ccc-029d483e846a" class="">
              We use your information to provide the services you requested,
              create and maintain your accounts, and keep an eye out for
              unauthorized activity on your accounts. We also use it to
              communicate with you about the products you're currently using,
              your customer support requests, new products you may like, chances
              for you to give us feedback, and policy updates. We analyze the
              information we collect to understand user needs and to improve our
              websites and services.
            </p>
            <p id="fc471890-6b9f-4cb7-87cb-315699a231c0" class="">
              We're required to have a legal basis for collecting and processing
              your information. In most cases, we either have you and/or your
              Employer’s consent or need the information to provide the service
              you've requested from us. When that's not the case, we must
              demonstrate that we have another legal basis, such as our
              legitimate business interests.
            </p>
            <p id="8e2450c9-7387-43a1-b1b1-a1ed923e95a9" class="">
              We do not use your information for anything else other than the
              ones required purely to provide the services you or your business
              requested.
            </p>
            <p id="1264129a-346a-449f-ab1e-67ef269603e6" class="">
              You can decline certain kinds of information use either by not
              providing the information in the first place or by opting out
              later. You can also disable cookies to prevent your browser from
              giving us information, but if you do so, certain website features
              may not work properly. We completely disable non-essential and
              intrusive third-party cookies from all our websites and products.
            </p>
            <p id="8dcb6f87-402f-448f-bb2c-fe8c9bf84770" class="">
              We limit access to your personal information to our employees and
              contractors who have a legitimate need to use it. If we share your
              information with other parties (like developers, service
              providers, domain registrars), they must have appropriate security
              measures and a valid reason for using your information, typically
              to serve you.
            </p>
            <p id="29a08288-329c-4059-8330-1d9670107bee" class="">
              We keep your personal information for as long as it is required
              for the purposes stated in this Privacy Policy. When we no longer
              have a legitimate need to process your information, we will
              delete, anonymize, or isolate your information, whichever is
              appropriate.
            </p>
            <h2 id="2ad7184c-4631-49a4-bf2b-91509fffb493" class="">
              <strong>Part II</strong>
            </h2>
            <h3 id="365a8dae-6462-4ac4-97c2-7317b7119800" class="">
              <strong>
                Information that Neatprocess processes on your behalf
              </strong>
            </h3>
            <p id="6af40813-9cae-414a-b97d-936df138eada" class="">
              If you handle other people's data using our products, such as
              information about your customers or employees, you are entrusting
              that data to us for processing. The data you entrust to us for
              processing is called service data.
            </p>
            <p id="a4976131-29de-454e-918c-44dc360c2833" class="">
              You own your service data. We protect it, limit access to it, and
              only process it according to your instructions. You may access it,
              share it through third-party integrations, and request that we
              export or delete it.
            </p>
            <p id="5360ee43-7e3c-433a-9ba4-e645c73de255" class="">
              We hold the data in your account as long as you choose to use
              Neatprocess Services. After you terminate your account, the
              account will be inactivated immediately, your data will be
              automatically deleted from our active database within 6 months and
              from our backups within 3 months after that.
            </p>
            <p id="d4ed527f-8153-4738-8362-c947fd611b5a" class="">
              If you are in the European Economic Area and you believe that
              someone has entrusted your information to us for processing (for
              instance, your employer or a company whose services you use), you
              can request certain actions from us regarding your data. To
              exercise those data rights, please contact the person or company
              that entrusted the data to us and we will work with them on your
              request.
            </p>
            <h2 id="6f17b3d7-1291-4fa7-a3b2-8de1f1507686" class="">
              <strong>Part III</strong>
            </h2>
            <h3 id="47c4379a-72cd-46b2-8621-f074e521e4c5" class="">
              <strong>General</strong>
            </h3>
            <p id="06acb148-570e-4c76-9594-3bf8b8c0a972" class="">
              There are some limitations to the privacy we can promise you. We
              will disclose personal information if it's necessary to comply
              with a legal obligation, prevent fraud, enforce an agreement, or
              protect our users' safety.
            </p>
            <p id="3b5c3edf-d042-4ee9-a0c2-8c1a2170cb5b" class="">
              Third-party websites and social media widgets have their own
              separate privacy policies. Always check the relevant privacy
              policy before sharing personal information with third parties.
            </p>
            <p id="9248e788-20c4-473d-b5d4-3d62d2571ea8" class="">
              You can always contact us to: ask questions about our privacy
              practices, request a GDPR-compliant Data Processing Addendum,
              alert us if you believe we have collected personal data from a
              minor, or ask to have your personal information removed from our
              blogs or forums. You can also check our Security Policy.
            </p>
            <p id="db6a7aff-7948-4288-9477-ad4dadec5047" class="">
              We will contact you to let you know if we make any major changes
              to our privacy policy.
            </p>
            <h1 id="f2876f3b-157d-49d8-b147-f16c4dab212b" class="">
              <strong>PRIVACY POLICY</strong>
            </h1>
            <h2 id="840aa3f0-81c0-4522-8fdc-997f2dee7d09" class="">
              <strong>Neatprocess’ Privacy Commitment</strong>
            </h2>
            <p id="8c8bc3e2-73eb-4578-96ba-8a7ee95ce74b" class="">
              We ask for only the least amount of information necessary,
              gathering only what we believe is essential for doing business, or
              for the specific transaction at hand. We let customers know the
              information we have on them and allow them to opt out of specific
              engagements. Our biggest commitment is that we do not make a
              single dollar from advertising revenue—never have, never will—even
              from the free editions of our products. This means we avoid the
              fundamental conflict of interest between gathering customer
              information and fueling advertising revenue, and the unavoidable
              compromises in customer privacy that it brings.
            </p>
            <p id="5b788dee-7ca3-42a9-b5fd-7d3cf585079e" class="">
              We always try to bring in more customers by the quality of our
              services. We believe that's the right way to do business.
            </p>
            <p id="a92e131f-d6d6-4ea4-89b6-cc2c5bcb1e1d" class="">
              The goal of this policy is to make explicit the information we
              gather, how we will use it, and how we will not.
            </p>
            <h2 id="791d8d43-9ec1-4b2e-bdd4-b3f7185bd734" class="">
              <strong>Scope of this Privacy Policy</strong>
            </h2>
            <p id="e295a8e7-4cf5-405e-bbf8-6e9eeef916c0" class="">
              This Privacy Policy applies to all Neatprocess websites that link
              to it. It also applies to the products and services provided by us
              through these websites.
            </p>
            <p id="f09d4479-4215-4224-b9aa-773d73410277" class="">
              <strong>This Privacy Policy is divided into three parts:</strong>
            </p>
            <p id="fc0f78e3-8cf2-4f25-85e6-470cebf32a6a" class="">
              <strong>
                Part I – Information Neatprocess collects and controls
              </strong>
            </p>
            <p id="27953028-062f-4f18-9311-98af0ff25080" class="">
              This part deals with how we collect and use information about
              website visitors, potential customers, users of our products and
              services, and others who contact us through forms or email
              addresses published on or linked to our websites.
            </p>
            <p id="dd672a8d-5f6a-47e5-ba8c-36bddf5a10da" class="">
              <strong>
                Part II – Information that Neatprocess processes on your behalf
              </strong>
            </p>
            <p id="9077d40c-3cce-4876-8b8f-6196c62dd0c4" class="">
              This part deals with how we handle data that you entrust to us
              when you use our products and services, or when you share any
              personal or confidential information with us while requesting
              customer support.
            </p>
            <p id="6836029d-f283-4a2d-a873-d39fba8575ea" class="">
              <strong>Part III – General</strong>
            </p>
            <p id="e7843c24-d8cb-4396-8fa3-72182f3c6eb3" class="">
              This part deals with topics that are relevant to both Parts I and
              II, and other general topics such as our security commitments and
              how we will inform you when we change this Privacy Policy.
            </p>
            <h2 id="102f3496-3fad-46dc-855c-f004b4c83244" class="">
              <strong>
                Part I – Information Neatprocess collects and controls
              </strong>
            </h2>
            <h3 id="bdc1c428-9abd-4614-b3fc-9be62205ebdc" class="">
              <strong>What information we collect</strong>
            </h3>
            <p id="08cca589-7212-438a-aa93-161db862bf43" class="">
              We collect information about you only if we need the information
              for some legitimate purpose. We will have information about you
              only if (a) you have provided the information yourself, (b) we
              have automatically collected the information, or (c) We obtained
              the information from a third party (social-media). Below we
              describe the various scenarios that fall under each of those three
              categories and the information collected in each one.
            </p>
            <h3 id="7ae9aaf7-c8f6-4fd5-b31b-500673d9c261" class="">
              <strong>Information that you provide us:</strong>
            </h3>
            <h3 id="1f78f2ca-f3ce-4b2d-8703-bd019dab1a3a" class="">
              <strong>Account signup</strong>
            </h3>
            <p id="2b253d31-d71b-4ec8-b335-183fc7e57f89" class="">
              When you sign up for an account to access one or more of our
              services, we ask for information like your name, email address,
              role, department and company to complete the account signup
              process. You'll also be required to choose a unique username and a
              password for accessing the created account. You may also provide
              us with more information such as your photo, time zone and
              language, but we don’t require that information to sign up for an
              account.
            </p>
            <h3 id="dccaa6d9-d985-4be3-98a4-eb32bd387a19" class="">
              <strong>Event registrations and other form submissions</strong>
            </h3>
            <p id="f4a53448-a109-4b40-9407-a25567f686f7" class="">
              We record information that you submit when you (i) register for
              any event, including webinars or seminars, (ii) subscribe to our
              newsletter or any other mailing list, (iii) submit a form in order
              to download any product, whitepaper, or other materials, (iv)
              participate in contests or respond to surveys, or (v) submit a
              form to request customer support, get a quote/demo or to contact
              us for any other purpose.
            </p>
            <h3 id="81f37e21-5a4b-487b-8583-4cbba4d7eb56" class="">
              <strong>Information that we collect automatically</strong>
            </h3>
            <h3 id="d8f3459f-b4da-44c0-9f52-3cb80b45f5d0" class="">
              <strong>Information given by your employer</strong>
            </h3>
            <h3 id="e5d7c34b-5f8a-4eb8-9933-b314e40364a7" class="">
              We get information about the employees who are authorized to use
              the platform from their employers. We sometimes do get some basic
              information about collaborators, who do not belong to the client’s
              organization. We do this so that we know who should be let to
              sign-in into the platform.
            </h3>
            <h3 id="de7e750a-cb0c-438e-a709-cd392ca91d76" class="">
              <strong>Information from browsers, devices and servers</strong>
            </h3>
            <p id="4ec57241-187b-41ae-b9e4-afe797de89fe" class="">
              When you visit our websites, we collect information that web
              browsers, mobile devices and servers make available, such as the
              internet protocol address, browser type, time zone, referring URL,
              date and time of access, operating system, mobile device
              manufacturer and mobile network information. We include these in
              our log files to understand more about visitors to our websites.
            </p>
            <h3 id="8595246d-90be-4303-a956-620d110d6732" class="">
              <strong>Information from application logs</strong>
            </h3>
            <p id="fb46face-2d54-431b-aeca-3d19275d393b" class="">
              We collect information about your use of our products and services
              using in-house usage analytics tools, and use it to understand how
              your use and needs can improve our products. This information
              includes clicks, features accessed, access time and frequency,
              errors generated, performance data and user settings.
            </p>
            <h3 id="1fa2893b-6240-4c20-bb6a-0a241214e69b" class="">
              <strong>Information that we collect from third parties</strong>
            </h3>
            <h3 id="8feb44b0-224b-4e20-8d25-6ad17a3fb783" class="">
              <strong>
                Information from social media sites and other publicly available
                sources
              </strong>
            </h3>
            <p id="2e696bd7-a835-4f79-a799-cf8d93b7f4d8" class="">
              When you provide feedback or reviews about our products, interact,
              or engage with us on social media sites such as LinkedIn through
              posts, comments, questions and other interactions, we may collect
              such publicly available information.
            </p>
            <h3 id="bf6b1aa9-f200-4c35-aa71-bc97fcf3f6c4" class="">
              <strong>Purposes for using information</strong>
            </h3>
            <p id="12bad66d-7387-483c-b1c8-90b692e5494e" class="">
              In addition to the purposes mentioned above, we may use your
              information for the following purposes:
            </p>
            <ul id="62bea7fc-6c54-42ea-9452-6c9c3b7c0999" class="bulleted-list">
              <li style={{listStyleType:"disc"}}>
                To communicate with you (such as through email) about products
                that you have downloaded and services that you have signed up
                for, changes to this Privacy Policy, changes to the Terms of
                Service, or important notices;
              </li>
            </ul>
            <ul id="f6029d82-0fda-47e1-9fc2-84d740826b11" class="bulleted-list">
              <li style={{listStyleType:"disc"}}>
                To keep you posted on new products and services, upcoming
                events, offers, promotions and other information that we think
                will be of interest to you;
              </li>
            </ul>
            <ul id="b69d94cc-8a5d-4dd1-8774-eb94d56a2e87" class="bulleted-list">
              <li style={{listStyleType:"disc"}}>
                To ask you to participate in surveys, or to solicit feedback on
                our products and services;
              </li>
            </ul>
            <ul id="61bc7218-e31b-43d6-9291-c3b742eb16fc" class="bulleted-list">
              <li style={{listStyleType:"disc"}}>
                To set up and maintain your account, and to do all other things
                required for providing our services.
              </li>
            </ul>
            <ul id="f1f77976-5ede-40b8-baef-a98d70b13a50" class="bulleted-list">
              <li style={{listStyleType:"disc"}}>
                To understand how users use our products and services, to
                monitor and prevent problems, and to improve our products and
                services;
              </li>
            </ul>
            <ul id="981f7a9e-db50-4bfb-a982-833a578aa6bb" class="bulleted-list">
              <li style={{listStyleType:"disc"}}>
                To detect and prevent fraudulent and other illegal activities,
                to report spam, and to protect the rights and interests of our
                users, third parties and the public
              </li>
            </ul>
            <h3 id="c558a183-7681-47db-b5f1-88b7e25c6276" class="">
              <strong>Who we share your information with</strong>
            </h3>
            <p id="4bc8d9e9-046f-4686-8b51-9f70190f37c5" class="">
              We do not sell any personal information. We share your information
              only in the ways that are described in this Privacy Policy, and
              only with parties who adopt appropriate confidentiality and
              security measures.
            </p>
            <p id="78cce19f-e476-4a8c-9155-f91ff70f5d27" class="">
              <strong>Employees and independent contractors</strong>
            </p>
            <p id="d76972c1-7fb5-40d8-a235-f121645ebb6b" class="">
              Employees and independent contractors have access to the
              information covered in Part I on a need-to-know basis. We require
              all employees and independent contractors to follow this Privacy
              Policy for personal information that we share with them.
            </p>
            <p id="aa291364-db5f-4940-b0cb-df1bd4076afb" class="">
              <strong>Third-party service providers</strong>
            </p>
            <p id="a4eb23f0-12b9-4fe4-8422-4439852e61ae" class="">
              We may need to share your personal information and aggregated or
              de-identified information with third-party service providers that
              we engage. These service providers are authorized to use your
              personal information only as necessary to provide these services
              to us.
            </p>
            <h3 id="76ddba14-302e-4212-a2be-0edce22f78c9" class="">
              <strong>
                Your rights with respect to information we hold about you as a
                controller
              </strong>
            </h3>
            <p id="cb4bc6f9-9422-4010-9d35-7c2467665508" class="">
              If you are in the European Economic Area (EEA), you have the
              following rights with respect to information that we hold about
              you. We undertake to provide you the same rights no matter where
              you choose to live.
            </p>
            <p id="c7384c1e-7aa7-4e54-ba8b-b0c759f7a03e" class="">
              <strong>Right to access :</strong> You have the right to access
              (and obtain a copy of, if required) the categories of personal
              information that we hold about you, including the information's
              source, purpose and period of processing, and the persons to whom
              the information is shared.
            </p>
            <p id="e2bf96f1-9825-4cb3-a5d8-23294b719c6e" class="">
              <strong>Right to rectification :</strong> You have the right to
              update the information we hold about you or to rectify any
              inaccuracies. Based on the purpose for which we use your
              information, you can instruct us to add supplemental information
              about you in our database.
            </p>
            <p id="4c31dd1e-2535-476f-8f54-db8357b47b75" class="">
              <strong>Right to erasure :</strong> You have the right to request
              that we delete your personal information in certain circumstances,
              such as when it is no longer necessary for the purpose for which
              it was originally collected.To exercise these rights, data
              subjects can contact us at support@neatprocess.com. We are
              committed to responding to such requests promptly and ensuring
              that personal data is handled in accordance with legal
              requirements and our privacy policies.
            </p>
            <p id="0642f8f4-5428-4182-a1d5-c1c46e6248f6" class="">
              <strong>Right to restriction of processing :</strong> You may also
              have the right to request to restrict the use of your information
              in certain circumstances, such as when you have objected to our
              use of your data but we need to verify whether we have overriding
              legitimate grounds to use it.
            </p>
            <p id="75d7d58c-1b25-47ed-b31f-833927a334e1" class="">
              <strong>Right to data portability :</strong> You have the right to
              transfer your information to a third party in a structured,
              commonly used and machine-readable format, in circumstances where
              the information is processed with your consent or by automated
              means.
            </p>
            <p id="55a16b50-01f2-423d-90f5-6eb1fde2ebca" class="">
              <strong>Right to object :</strong> You have the right to object to
              the use of your information in certain circumstances, such as the
              use of your personal information for direct marketing.
            </p>
            <p id="fb76ea51-72c9-4b89-a008-85750730a08c" class="">
              <strong>Right to complain :</strong> You have the right to
              complain to the appropriate supervisory authority if you have any
              grievance against the way we collect, use or share your
              information. This right may not be available to you if there is no
              supervisory authority dealing with data protection in your
              country.
            </p>
            <h3 id="08e787b6-8b31-41f1-9f0c-f84be05f075a" class="">
              <strong>Retention of information</strong>
            </h3>
            <p id="b27f3902-a4f4-4a86-9782-a14d6d23be33" class="">
              We retain your personal information for as long as it is required
              for the purposes stated in this Privacy Policy. Sometimes, we may
              retain your information for longer periods as permitted or
              required by law, such as to maintain suppression lists, prevent
              abuse, if required in connection with a legal claim or proceeding,
              to enforce our agreements, for tax, accounting, or to comply with
              other legal obligations. When we no longer have a legitimate need
              to process your information, we will delete or anonymize your
              information from our active databases. We will also securely store
              the information and isolate it from further processing on backup
              discs until deletion is possible.
            </p>
            <h2 id="0ef8bda3-d5b6-4c67-93d3-2f9ad992f0d9" class="">
              <strong>
                Part II – Information that Neatprocess processes on your behalf
              </strong>
            </h2>
            <h3 id="9e76e52f-7e38-4744-9a86-561f54c3082b" class="">
              <strong>Information entrusted to us and purpose</strong>
            </h3>
            <h3 id="261cea61-847e-404b-b1c0-0ccb66abfbb1" class="">
              <strong>Information provided in connection with services</strong>
            </h3>
            <p id="5b9d413f-cfc7-4a39-ad7e-aea1a7c39267" class="">
              You may entrust information that you or your organization (“you”)
              control, to us in connection with use of our services or for
              requesting technical support for our products. This includes
              information regarding your customers and your employees (if you
              are a controller) or data that you hold and use on behalf of
              another person for a specific purpose, such as a customer to whom
              you provide services (if you are a processor). The data may either
              be stored on our servers when you use our services, or transferred
              or shared to us as part of a request for technical support or
              other services.
            </p>
            <p id="93369ab3-e906-45d8-8f73-93b662967d01" class="">
              (All the information entrusted to Neatprocess is collectively
              termed “service data”)
            </p>
            <h3 id="e4209e06-3d0f-4a29-b2a7-18f68a625ebd" class="">
              <strong>Ownership and control of your service data</strong>
            </h3>
            <p id="3007f565-f8ee-4834-ab0e-c46bd981b5c9" class="">
              We recognize that you own your service data. We provide you
              complete control of your service data by providing you the ability
              to (i) access your service data, (ii) share your service data
              through supported third-party integrations, and (iii) request
              export or deletion of your service data.
            </p>
            <h3 id="2b70bca6-5446-410c-8c14-f9cac1c557f8" class="">
              <strong>How we use service data</strong>
            </h3>
            <p id="ca4f082d-32c6-4ec4-a424-93b87db890b0" class="">
              We process your service data when you provide us instructions
              through the various modules of our services. For example: You
              might use a notifying module within our tool to send notification
              to a person. In that case we use our email services to send them
              emails.
            </p>
            <h3 id="67cb5a44-18ea-43de-907c-5ed1adf423bd" class="">
              <strong>Who we share service data with</strong>
            </h3>
            <p id="e54e561a-e6f0-4f92-8a6a-a28d4ced9d35" class="">
              <strong>Employees and independent contractors</strong>
            </p>
            <p id="509c348c-1940-4418-b241-3573e91ac14d" class="">
              We may provide access to your service data to our employees and
              individuals who are independent contractors of the Neatprocess in
              providing the services (collectively our “employees”) so that they
              can (i) identify, analyze and resolve errors, (ii) manually verify
              scanned images that you submit to us to verify the accuracy of
              optical character recognition. We ensure that access by our
              employees to your service data is restricted to specific
              individuals, and is logged and audited. Our employees will also
              have access to data that you knowingly share with us for technical
              support or to import data into our products or services. We
              communicate our privacy and security guidelines to our employees
              and strictly enforce privacy safeguards within Neatprocess.
            </p>
            <p id="047d736e-59d4-4639-aee5-a60459ca33d8" class="">
              <strong>Collaborators and other users</strong>
            </p>
            <p id="946f209e-23ce-4e7a-b220-d385d0eadfad" class="">
              Some of our products or services allow you to collaborate with
              other users or third parties. Initiating collaboration may enable
              other collaborators to view some or all of your profile
              information. For example, when you edit a document/value that you
              have shared with other persons for collaboration, your name and
              profile picture will be displayed next to your edits to allow your
              collaborators to know that you made those edits.
            </p>
            <p id="cb75a960-0249-4ec5-8ceb-76c9cb66fb01" class="">
              <strong>Change Management in Subcontracting Arrangements</strong>
            </p>
            <p id="2133813c-9b4a-45cb-a585-e77ff916a062" class="">
              Neatprocess recognizes the importance of maintaining stringent
              data protection and privacy standards throughout our
              subcontracting processes. To this end, any changes to our existing
              subcontracting arrangements must receive explicit prior approval
              from the data controller.
            </p>
            <h3 id="a98e3be0-6c81-4f5b-937e-f33e0471b88d" class="">
              <strong>Retention of information</strong>
            </h3>
            <p id="fb093ba5-a3e7-4376-8539-003c845cd347" class="">
              Our goal is to keep your personal information for as short period
              as possible to achieve the purpose for which your personal
              information is collected. We will retain your personal information
              as needed to fulfill the purposes for which it was collected. We
              may retain your personal information as needed to provide you
              services, comply with our business requirements and legal
              obligations, resolve disputes and enforce our rights and
              agreements.
            </p>
            <p id="329c4f40-2a22-4409-ad5f-a3cdcd4a29c5" class="">
              We consider the following criteria when we are making decisions on
              how long we will retain your personal information:
            </p>
            <p id="bdd9c187-7de6-4e8c-aec7-a56bb5d6f466" class="">
              ‍
            </p>
            <ul id="1924b6ee-4484-44c9-9b15-7f83ec856a8e" class="bulleted-list">
              <li style={{listStyleType:"disc"}}>
                whether the personal information is necessary to operate or
                provide our services. For example, account information may be
                retained for a longer period of time based on the MSA that
                governs use of the Solution;
              </li>
            </ul>
            <ul id="fb7ddee2-5be1-46ce-84c3-451ebd3efb68" class="bulleted-list">
              <li style={{listStyleType:"disc"}}>
                how long we need to retain the personal information to comply
                with our legal obligations and any audit requirements;
              </li>
            </ul>
            <ul id="7c16f010-6e25-42a1-8273-64dc78238fd5" class="bulleted-list">
              <li style={{listStyleType:"disc"}}>
                our legitimate interests or legal purposes, such as improving
                our products and services, fraud prevention, record-keeping,
                promoting safety, security and integrity, or enforcing our legal
                rights
              </li>
            </ul>
            <p id="ec171aa9-4378-4483-9452-2141ce9e041f" class="">
              Post deletion a destruction certificate would be provided to the
              enterprise
            </p>
            <h3 id="b539a16e-80d7-41f0-84ee-6a5b9d185097" class="">
              <strong>Data subject requests</strong>
            </h3>
            <p id="1e2074d6-cd30-469a-89bd-b07a700b04ec" class="">
              If you are from the European Economic Area and you believe that we
              store, use or process your information on behalf of one of our
              customers, please contact the customer if you would like to
              access, rectify, erase, restrict or object to processing, or
              export your personal data. We will extend our support to our
              customer in responding to your request within a reasonable
              timeframe.
            </p>
            <h2 id="fe6b46b8-e4cb-4a31-bc42-5e7d2447e492" class="">
              <strong>Part III – General</strong>
            </h2>
            <h3 id="942e8567-7f7e-44b0-b8d8-6f03ddfe3d25" class="">
              <strong>Children’s personal information</strong>
            </h3>
            <p id="f3164f6c-6edf-4791-bdc7-e7a542026a4f" class="">
              Our products and services are not directed to individuals under
              16. We do not knowingly collect personal information from children
              who are under 16 years of age for its own purposes. If we become
              aware that a child under 16 has provided us with personal
              information, we will take steps to delete such information. If you
              believe that a child under 16 years has provided personal
              information to us, please write to privacy@neatprocess.com with
              the details, and we will take the necessary steps to delete the
              information we hold about that child.
            </p>
            <h3 id="9bc744b4-d591-47d7-afc1-6fe562259f61" class="">
              <strong>How secure is your information</strong>
            </h3>
            <p id="8f470400-c981-4ce6-b1ad-a5e0edab4c3d" class="">
              At Neatprocess, we take data security very seriously.
            </p>
            <p id="8efa0bb2-0701-495c-8af9-fa64e8b70beb" class="">
              We are in progress of getting certified for industry standards
              mentioned like ISO 27001:2013.
            </p>
            <p id="33edc5e3-6488-40b7-9135-28d29dfc0b97" class="">
              We have taken steps to implement appropriate administrative,
              technical &amp; physical safeguards to prevent unauthorized
              access, use, modification, disclosure or destruction of the
              information you entrust to us. If you have any concerns regarding
              the security of your data, we encourage you to check our Security
              Policy or write to us at security@neatprocess.com with any
              questions.
            </p>
            <h3 id="6430525e-2f56-42ac-9a59-048713e4d604" class="">
              <strong>Data Protection Officer</strong>
            </h3>
            <p id="a5254fcc-74b1-4857-9e2c-30d9191c9d1b" class="">
              We have appointed a Data Protection Officer to oversee our
              management of your personal information in accordance with this
              Privacy Policy. If you have any questions or concerns about our
              privacy practices with respect to your personal information, you
              can reach out to our Data Protection Officer by sending an email
              to{" "}
              <a href="mailto:dpo@neatprocess.com">
                <span style={{borderBottom:"0.05em solid"}}>
                  dpo@neatprocess.com
                </span>
              </a>
              , Address: # 1099, 4/10 Main Road, 2nd Stage E &amp; F Block,
              Ramakrishna Nagar, Mysore, Karnataka 570022 IN
            </p>
            <h3 id="a3be54a1-56dc-468e-a5c2-c022d3453cdb" class="">
              <strong>Automation and Artificial Intelligence</strong>
            </h3>
            <p id="b6d92d0d-0d3b-4879-8e24-69be97afbcc1" class="">
              In order to provide enhanced productivity and predictive
              capabilities to our users, we employ a variety of technologies
              such as regex parsing, template matching, artificial intelligence
              and machine learning. In keeping with our promise not to exploit
              your data in a way that is not respectful of your privacy and
              confidentiality expectations, we do not make use of service data
              for these technologies.
            </p>
            <p id="1e4430e2-cc45-4fcb-b8d0-fee96ba4e9a1" class="">
              Our automation and artificial intelligence technologies are
              powered by our own organization's data such as internal
              communications, communications with customers and internal
              documents as well as free and paid external sources.
            </p>
            <h3 id="8673784d-3b13-4e03-b2b3-f12c5e7b98b1" class="">
              <strong>Disclosures in compliance with legal obligations</strong>
            </h3>
            <p id="5ff00b05-8f78-4d8f-80ca-f46dfe5c703a" class="">
              We may be required by law to preserve or disclose your personal
              information and service data to comply with any applicable law,
              regulation, legal process or governmental request, including to
              meet national security requirements.
            </p>
            <h3 id="2dc933f1-c6ed-4429-92ac-fb153fbe43dc" class="">
              <strong>Business Transfers</strong>
            </h3>
            <p id="bc824fe4-26c4-4695-a273-1cd305f4ccc5" class="">
              We do not intend to sell our business. However, in the unlikely
              event that we sell our business or get acquired or merged, we will
              ensure that the acquiring entity is legally bound to honor our
              commitments to you. We will notify you via email or through a
              prominent notice on our website of any change in ownership or in
              the uses of your personal information and service data. We will
              also notify you about any choices you may have regarding your
              personal information and service data.
            </p>
            <h3 id="40c9d69e-7218-4b0b-800a-64bf2673b19b" class="">
              <strong>Compliance with this Privacy Policy</strong>
            </h3>
            <p id="f10f5a44-743a-4655-a2b1-8aa3d044c81d" class="">
              We make every effort, including periodic reviews, to ensure that
              personal information you provide is used in conformity with this
              Privacy Policy. If you have any concerns about our adherence to
              this Privacy Policy or the manner in which your personal
              information is used, kindly write to us privacy@neatprocess.com
              We'll contact you, and if required, coordinate with the
              appropriate regulatory authorities to effectively address your
              concerns.
            </p>
            <h3 id="c2e2dc34-bba3-4484-b431-6530e73fe825" class="">
              <strong>Notification of changes</strong>
            </h3>
            <p id="be7ff82d-67c2-4bfa-8226-de1772c84f86" class="">
              We may modify the Privacy Policy at any time, upon notifying you
              through a service announcement or by sending an email to your
              primary email address.
            </p>
            <p id="d97bb349-32f6-45da-af03-b33b5189f8fe" class=""></p>
            <p id="67120a14-9848-4b3c-b446-618a0c702615" class="">
              **Brand Ownership "Neatprocess" is a brand owned by Kriyarth
              Technologies Private Limited. All references to "Neatprocess"
              within this Privacy Policy and other company documents refer to
              the brand, products, and services under the ownership and
              operation of Kriyarth Technologies Private Limited.**
            </p>
            <p id="fa11b4ee-86da-4589-9e60-09bfd6118bed" class=""></p>
          </div>
        </article>
      </Box>
      <Footer />
    </ImageBackground>
  );
};

export default PrivacyPolicy;
