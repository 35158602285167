import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./layouts/home/Home";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./assets/theme";
import PrivacyPolicy from "layouts/termsAndPolicies/PrivacyPolicy";
import SecurityPolicy from "layouts/termsAndPolicies/SecurityPolicy";
import GDPR from "layouts/termsAndPolicies/GDPR";
import Products from "layouts/products/Products";
import ClaimDesk from "layouts/products/components/ClaimDesk";
import Blog from "layouts/blog/Blog";
import ScrollToTop from "components/utils/ScrollToTop ";
import DocStore from "layouts/products/components/DocStore";
import DeliveryDesk from "layouts/products/components/DeliveryDesk";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/terms/privacy-policy" element={<PrivacyPolicy />} />
        <Route
          exact
          path="/terms/security-policy"
          element={<SecurityPolicy />}
        />
        <Route exact path="/terms/gdpr-policy" element={<GDPR />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/claim-desk" element={<ClaimDesk />} />
        <Route path="/products/doc-store" element={<DocStore />} />
        <Route path="/products/delivery-desk" element={<DeliveryDesk />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </ThemeProvider>
  );
};

export default App;
