import { useTheme } from "@emotion/react";
import { Box, colors, Link as MuiLink } from "@mui/material";
import logo from "assets/images/ef-logo.png";
import facebook from "assets/images/svg/logo-facebook.svg";
import linkedin from "assets/images/svg/logo-linkedin.svg";
import twitter from "assets/images/svg/logo-twitter.svg";
import instagram from "assets/images/svg/logo-instagram.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, text, error, secondary } = palette;
  const { pxToRem } = functions;
  
  const tabs = [
    { name: "Privacy Policy", link: "/terms/privacy-policy" },
    { name: "Security Policy", link: "/terms/security-policy" },
    { name: "GDPR", link: "/terms/gdpr-policy" },
    { name: "Products", link: "/products" },
    { name: "Blogs", link: "/blog" },
  ];

  const socialMedia = [
    // { icon: facebook, link: "" },
    { icon: linkedin, link: "https://www.linkedin.com/company/neatprocess" },
    // { icon: twitter, link: "" },
    // { icon: instagram, link: "" },
  ];

  return (
    <Box sx={{backgroundColor: white.main, position: 'sticky', top: 'calc(100vh - 240px)'}}>
      <Box
        sx={{
          background: "linear-gradient(to bottom, white, #F2F0FC)",
          height: "100px",
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          padding: "3rem",
          paddingBottom: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img src={logo} height={"50"} />
          <Box
            sx={{
              background: `-webkit-linear-gradient(45deg, ${primary.main}, ${secondary.main})`,
              backgroundClip: "text",
              textFillColor: "transparent",
              fontSize: "24px",
              fontWeight: "bold",
            }}
          >
            NeatProcess
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            whiteSpace: "nowrap",
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: "1rem",
          }}
        >
          {tabs.map((tab) => (
            <Link to={tab.link}>
              <Box sx={{ fontSize: typography.size["sm"], color: text.main }}>
                {tab.name}
              </Box>
            </Link>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            marginBottom: '1rem'
          }}
        >
          {socialMedia.map((tab) => (
            <MuiLink target="_blank" href={tab.link}>
              <img src={tab.icon} height={"24px"} />
            </MuiLink>
          ))}
        </Box>

        {/* <Box
          sx={{
            display: "flex",
            gap: "3rem",
            fontSize: typography.size["sm"],
            marginY: "1rem",
          }}
        >
          <Box sx={{ color: text.disabled }}>
            <span style={{ color: primary.main }}>Email:</span>{" "}
            sales@neatprocess.com
          </Box>
          <Box sx={{ color: text.disabled }}>
            <span style={{ color: primary.main }}>Address:</span> Lorem Ipsum,
            Lorem Ipsum, Lorem Ipsum Lorem, 678039
          </Box>
        </Box> */}
      </Box>
      <Box
        sx={{
          background: `linear-gradient(45deg, ${primary.main}, ${secondary.main})`,
          padding: ".5rem",
          textAlign: "center",
          color: white.main,
          fontSize: typography.size["sm"],
        }}
      >
        © 2024 NeatProcess. All Copyrights are reserved.
      </Box>
    </Box>
  );
};

export default Footer;
