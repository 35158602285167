import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import { Button, CardActions } from "@mui/material";
import { GradientButton, OutlinedButton } from "components/button/buttons";
import boxShadow from "assets/theme/functions/boxShadow";
import { share } from "utils";
import { Link } from "react-router-dom";

export default function ActionAreaCard({
  title,
  content,
  image,
  sx,
  contentStyle,
  shareURL,
}) {
  return (
    <Card
      sx={{
        maxWidth: 345,
        borderRadius: "10px",
        padding: '5px',
        height: "100%",
        boxShadow: "0 4px 5px 0 #6A6BFF99",
        margin: "auto",
        ...sx,
      }}
    >
      <CardActionArea>
        <Link to={shareURL}>
        <CardMedia
          component="img"
          height="140"
          image={image}
          alt="green iguana"
          sx={{
            padding: '1rem',
            paddingBottom: 0,
            borderRadius: '10px'
          }}
        />
        <CardContent sx={contentStyle}>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              WebkitLineClamp: 4,
              fontSize: ".9rem",
            }}
          >
            {content}
          </Typography>
        </CardContent>
        </Link>
        <CardActions sx={{
          padding: '0 1rem .8rem 1rem'
        }}>
          {shareURL && <OutlinedButton
            size={"small"}
            sx={{ boxShadow: "none" }}
            name={"Share"}
            onClick={() => share(shareURL, title, content)}
          />}
          {shareURL && <OutlinedButton
            size={"small"}
            sx={{ boxShadow: "none" }}
            name={"Read More"}
            component={Link}
            to={shareURL}
          />}
        </CardActions>
      </CardActionArea>
    </Card>
  );
}
