import { useTheme } from "@emotion/react";
import { MoveDown } from "@mui/icons-material";
import { Button } from "@mui/material";

export const GradientButton = ({ name, onClick, sx, ...props }) => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, text, error, secondary } = palette;
  const { pxToRem } = functions;

  return (
    <Button
      sx={{
        background: `linear-gradient(45deg, ${primary.main}, ${secondary.main})`,
        borderRadius: "100px",
        textTransform: "none",
        paddingX: "1rem",
        boxShadow: "0 4px 8px 0 #6A6BFF" + "99",
        color: white.main + " !important",
        ...sx,
      }}
      {...props}
      onClick={onClick}
    >
      {name || "Button"}
    </Button>
  );
};

export const OutlinedButton = ({ name, onClick, sx, ...props }) => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, text, error, secondary } = palette;
  const { pxToRem } = functions;

  return (
    <Button
      sx={{
        background: white.main,
        borderRadius: "100px",
        textTransform: "none",
        border: "1px solid " + primary.main,
        paddingX: "1rem",
        boxShadow: "0 4px 8px 0 #6A6BFF" + "99",
        color: text.main,
        ...sx,
      }}
      {...props}
      onClick={onClick}
    >
      {name || "Button"}
    </Button>
  );
};
