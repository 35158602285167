import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import NavBar from "components/navbar/NavBar";
import { Link } from "react-router-dom";
import { ImageBackground } from "components/background/background";
import Footer from "layouts/home/components/Footer";

const GDPR = () => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, text, error, secondary } = palette;
  const { pxToRem } = functions;
  return (
    <ImageBackground>
      <NavBar
        links={
          <>
            <Link to="/terms/privacy-policy">Privacy policy</Link>
            <Link to="/terms/security-policy">Security policy</Link>
            <Link to="/terms/gdpr-policy">GDPR</Link>
          </>
        }
      />
      <Box
        sx={{
          borderRadius: "20px",
          backgroundColor: "white.main",
          padding: "2rem 4rem",
          width: "79%",
          margin: "auto",
          maxWidth: "990px",
          marginY: "2rem",
          boxShadow: "0 4px 14px 0 #6A6BFF99",
          "@media (max-width: 700px)": {
            width: "89%",
            padding: "2rem",
          },
          "@media (max-width: 500px)": {
            width: "89%",
            padding: "1rem",
          },
        }}
      >
        <article id="675b50e7-9a6f-46c2-942a-a4b671da5b6c" class="page sans">
          <header>
            <h1 class="page-title">GDPR </h1>
            <p class="page-description"></p>
          </header>
          <div class="page-body">
            <h1 id="0d243d73-05c7-41a3-b155-83d9681a9a5a" class="">
              <strong>GDPR - What Neatprocess is doing about it</strong>
            </h1>
            <p id="177868db-68b0-4b91-bb28-a8ee01283458" class="">
              Neatprocess has always honored its users’ rights to data privacy
              and protection. Over the years, we’ve demonstrated our commitment
              to this by consistently exceeding industry standards. We have no
              need to collect and process users’ personal information beyond
              what is required for the functioning of our products, and this
              will never change. We have a privacy-conscious culture here, and
              GDPR is an opportunity for us to strengthen this even further.
            </p>
            <h2 id="0da31a44-4971-420b-9b15-a81eef2724ac" class="">
              <strong>What is GDPR?</strong>
            </h2>
            <p id="3e0e2c1d-757d-47ee-8e41-158e323ac857" class="">
              GDPR is an EU-wide privacy and data protection law that regulates
              how EU residents' data is protected by companies and enhances the
              control the EU residents have over their personal data. The GDPR
              is relevant to any globally operating company and not just the
              EU-based businesses and EU residents. Our customers’ data is
              important irrespective of where they are located, which is why we
              have implemented GDPR controls as our baseline standard for all
              our operations worldwide. GDPR has taken effect from 25th May
              2018.
            </p>
            <h2 id="a3515cf6-536a-48f4-8d82-8a384046fae4" class="">
              <strong>What is personal data?</strong>
            </h2>
            <p id="65b8a405-81f6-4faf-9196-a04785968e98" class="">
              Any data that relates to an identifiable or identified individual.
              GDPR covers a broad spectrum of information that could be used on
              its own or in combination with other pieces of information to
              identify a person. Personal data extends beyond a person’s name or
              email address. Some examples include financial information,
              political opinions, genetic data, biometric data, IP addresses,
              physical address, sexual orientation, and ethnicity.
            </p>
            <h2 id="c8516828-8815-4447-ad82-45434e1e4341" class="">
              <strong>How prepared is Neatprocess for GDPR?</strong>
            </h2>
            <p id="f1f970c3-0f72-4424-9308-8972adbb5de2" class="">
              We have acted on many fronts to adhere to this new regulation:
            </p>
            <ul id="0701517e-3129-4a72-94cc-5bdd76968efe" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>Awareness and Training:</strong> We have raised
                awareness across the organization through frequent discussions
                in our internal channels and trained employees to handle data
                appropriately. They now understand the importance of information
                security and the high standards set by GDPR.
              </li>
            </ul>
            <ul id="ba330d84-8c2d-4b31-9e56-687a723f78e9" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>Product Assessment and Enhancements:</strong> We have
                assessed all Neatprocess products individually against the
                requirements of the GDPR and have implemented new features that
                will give you more control over your data and ease your burden
                of achieving GDPR compliance.
              </li>
            </ul>
            <ul id="e2adfd6b-c28f-462d-aeb5-94d5a66b0cc4" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>Sub-processor Assessments:</strong> We have assessed our
                sub-processors (third-party service providers, partners) and
                streamlined the contract process with them to ensure that they
                have addressed the pressing needs of the current security and
                privacy world.
              </li>
            </ul>
            <ul id="3dd77af4-0d9b-4a42-a4d0-c7aa8aa340ea" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>
                  Privacy Champions and Data Protection Officer (DPO):
                </strong>{" "}
                We have appointed internal privacy champions for all our teams
                and a Data Protection Officer (DPO).
              </li>
            </ul>
            <ul id="f517cf71-af89-484e-9a59-054bb1a780b6" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>Privacy by Design:</strong> Our application teams have
                embraced the concept of privacy by design and have provided you
                more control over the data you store in our systems. These
                provisions may vary based on a product’s characteristics and
                domain. We constantly endeavor to provide you with more
                enhancements, which shall be rolled out in phases.
              </li>
            </ul>
            <ul id="437c5ba0-1f83-401d-8d02-8e5390c45276" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>Data Protection Impact Assessments (DPIA):</strong> We
                conducted DPIAs and, based on the results, have put in place
                appropriate controls on data processing and management.
              </li>
            </ul>
            <ul id="4d752dcd-0ed5-40ea-81f0-55f3d0b16af3" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>Internal Audits:</strong> We conducted internal audits
                of our products, processes, operations, and management. The
                findings were communicated to our teams, who have worked out the
                solutions to the identified problems.
              </li>
            </ul>
            <ul id="ba1824b6-2670-457d-b099-4c07c2efd60d" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>Data Security Improvements:</strong> Based on the DPIAs
                and internal audits, we have improved our data security methods
                and processes, including encrypting data at rest based on the
                level of sensitivity and likelihood of risks. We have developed
                in-house tools for better governance and discovery of data.
              </li>
            </ul>
            <ul id="60264eec-76c8-417e-a4f7-a897ab4864de" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>Database Cleanup:</strong> We have cleaned up our
                databases to ensure that we have only the latest and most
                accurate information. This cleanup process includes removing
                terminated and dormant accounts as per our Terms of Service.
              </li>
            </ul>
            <ul id="61b9301a-0019-44e5-b8e1-78e5d55e421b" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>Breach Notifications:</strong> When needed, breach
                notifications will be done according to our internal Privacy
                Incident Response policy. Customers will be notified of a breach
                within 72 hours after Neatprocess becomes aware of it. For
                general incidents, we will notify users through our blogs,
                forums, and social media. For incidents specific to an
                individual user or an organization, we will notify the concerned
                party through email (using their primary email address).
              </li>
            </ul>
            <ul id="f99227ad-adde-4949-a721-4a5342ce075d" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>Privacy Policy Revisions:</strong> We have revised our
                Privacy Policy to incorporate the requirements of the applicable
                privacy laws based on our data inventory, data flows, and data
                handling practices.
              </li>
            </ul>
            <h2 id="c95b78ed-913d-4e41-a050-c67c4394fe3a" class="">
              <strong>
                Compliance with Data Protection Laws and Regulations
              </strong>
            </h2>
            <p id="e6147afe-84b0-4188-a049-e151235d43b1" class="">
              Our organization is committed to adhering to relevant data
              protection laws and regulations. Below is a detailed list of
              specific articles and provisions that impact our data protection
              practices, particularly in relation to collecting and maintaining
              email addresses to track a person's performance within our
              application:
            </p>
            <h3 id="01b0c207-e6bb-4b0d-9fd9-baa8c2588319" class="">
              <strong>1. General Data Protection Regulation (GDPR)</strong>
            </h3>
            <p id="3498e0fe-8cb3-4e9d-8b0a-8f8be7769a44" class="">
              We make use of emails as an identifier, to provide the business
              with the services requested. It is assumed as an essential part of
              the service we provide. Our contracts with the client allows us to
              do this.
            </p>
            <ul id="9eed143d-12f1-4589-ac45-1d9bd67f0ef3" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>
                  Article 5: Principles relating to processing of personal data
                </strong>
                <ul
                  id="837d6b46-79ee-4e97-a45d-171a55332839"
                  class="bulleted-list"
                >
                  <li style={{ listStyleType: "circle" }}>
                    <strong>Lawfulness, fairness, and transparency:</strong> We
                    ensure all email addresses are processed lawfully, fairly,
                    and in a transparent manner.
                  </li>
                </ul>
                <ul
                  id="234015e4-6589-4d68-9e81-9c899b6b9854"
                  class="bulleted-list"
                >
                  <li style={{ listStyleType: "circle" }}>
                    <strong>Purpose limitation:</strong> Email addresses are
                    collected solely for tracking performance within our
                    application, as specified in our business contracts.
                  </li>
                </ul>
                <ul
                  id="e454609b-aeef-441c-8f15-79a505dd2d2c"
                  class="bulleted-list"
                >
                  <li style={{ listStyleType: "circle" }}>
                    <strong>Data minimization:</strong> Only the necessary email
                    addresses are collected.
                  </li>
                </ul>
                <ul
                  id="6cb742c7-dbf5-4de1-89ad-29c2798c32e5"
                  class="bulleted-list"
                >
                  <li style={{ listStyleType: "circle" }}>
                    <strong>Accuracy:</strong> We keep email addresses accurate
                    and up-to-date.
                  </li>
                </ul>
                <ul
                  id="3a0007c4-2e3a-475d-bdee-8021c913a961"
                  class="bulleted-list"
                >
                  <li style={{ listStyleType: "circle" }}>
                    <strong>Storage limitation:</strong> Email addresses are
                    retained only as long as necessary for tracking performance.
                  </li>
                </ul>
                <ul
                  id="6d5375b7-0c4d-4c15-8cd8-439120aa5f58"
                  class="bulleted-list"
                >
                  <li style={{ listStyleType: "circle" }}>
                    <strong>Integrity and confidentiality:</strong> We implement
                    appropriate security measures to protect email addresses.
                  </li>
                </ul>
              </li>
            </ul>
            <ul id="89e25be1-dff1-4df4-9840-866eb4eb86eb" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>Article 6: Lawfulness of processing</strong>
                <ul
                  id="dc32d1b7-7e07-4bcc-9a6d-96dd959f4bd6"
                  class="bulleted-list"
                >
                  <li style={{ listStyleType: "circle" }}>
                    We obtain valid consent from individuals and process email
                    addresses as necessary for the performance of a contract.
                  </li>
                </ul>
              </li>
            </ul>
            <ul id="57d6f406-23fa-4ded-b04a-f9865c6dfba0" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>Article 7: Conditions for consent</strong>
                <ul
                  id="c97811eb-e531-43e1-97e2-7bcabb9c847f"
                  class="bulleted-list"
                >
                  <li style={{ listStyleType: "circle" }}>
                    Consent for collecting email addresses is freely given,
                    specific, informed, and unambiguous.
                  </li>
                </ul>
              </li>
            </ul>
            <ul id="872adfce-0060-4835-b623-ffbb7faf76be" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>
                  Article 13: Information to be provided where personal data are
                  collected from the data subject
                </strong>
                <ul
                  id="55d73035-26d0-4218-9ed9-157c0a7e852b"
                  class="bulleted-list"
                >
                  <li style={{ listStyleType: "circle" }}>
                    Individuals are informed about the purposes of email
                    collection, data retention periods, and their rights.
                  </li>
                </ul>
              </li>
            </ul>
            <ul id="7be26a93-c0d1-4f10-be19-5277fb99b5f4" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>Article 32: Security of processing</strong>
                <ul
                  id="b968e74b-00cb-42ee-a969-2f35fced6515"
                  class="bulleted-list"
                >
                  <li style={{ listStyleType: "circle" }}>
                    We implement appropriate technical and organizational
                    measures to ensure the security of email addresses.
                  </li>
                </ul>
              </li>
            </ul>
            <h3 id="0c91b8e2-bcec-45cd-a817-14a258d7ff52" class="">
              <strong>
                2. California Online Privacy Protection Act (CalOPPA)
              </strong>
            </h3>
            <ul id="1bf405c7-4141-4c50-98f9-0c2f5d27581c" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>Privacy Policy Requirement</strong>
                <ul
                  id="a9cc9f7e-1e0a-4f07-99a9-921d459e25a6"
                  class="bulleted-list"
                >
                  <li style={{ listStyleType: "circle" }}>
                    A clear and conspicuous privacy policy is posted on our
                    website, detailing the types of personal data collected and
                    their uses.
                  </li>
                </ul>
              </li>
            </ul>
            <ul id="786c6380-7b07-4a6d-8a74-4f69bfa4e778" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>Do Not Track Disclosures</strong>
                <ul
                  id="ddde6859-0667-4b15-90cc-2654c8354b9e"
                  class="bulleted-list"
                >
                  <li style={{ listStyleType: "circle" }}>
                    We do not track you across sites. For more information
                    please refer our privacy policy
                  </li>
                </ul>
              </li>
            </ul>
            <p id="23bc017b-57a8-49ff-afc0-a60bd6e8bdc5" class="">
              **Brand Ownership "Neatprocess" is a brand owned by Kriyarth
              Technologies Private Limited. All references to "Neatprocess"
              within this Policy and other company documents refer to the brand,
              products, and services under the ownership and operation of
              Kriyarth Technologies Private Limited.**
            </p>
            <p id="61f36c09-523e-4ce7-9ebb-7bfc8fc1265b" class=""></p>
          </div>
        </article>
      </Box>
      <Footer />
    </ImageBackground>
  );
};

export default GDPR;
