import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import NavBar from "components/navbar/NavBar";
import { Link } from "react-router-dom";
import { ImageBackground } from "components/background/background";
import Footer from "layouts/home/components/Footer";
import claimdesk from "assets/images/blog/claimdesk/image.png";
import docStore from "assets/images/blog/docStore/image.png";
import deliveryDesk from "assets/images/blog/deliveryDesk/image.png";
import ActionAreaCard from "components/actionAreaCard/ActionAreaCard";

const Products = () => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, text, error, secondary } = palette;
  const { pxToRem } = functions;
  return (
    <ImageBackground>
      <NavBar
        links={
          <>
            <Link to="/products">Products</Link>
            <Link to={"/blog"}>Blogs</Link>
          </>
        }
      />
      <Box
        sx={{
          paddingY: "5rem",
          margin: "auto",
          display: "grid",
          gap: "3rem",
          justifyContent: "center",
          maxWidth: "1000px",
          gridTemplateColumns: `repeat(3, 1fr)`,
          "@media (max-width: 1000px)": {
            marginX: "2rem",
            gap: "2rem",
          },
          "@media (max-width: 800px)": {
            gridTemplateColumns: `repeat(2, 1fr)`,
          },
          "@media (max-width: 500px)": {
            marginX: "1.5rem",
            gridTemplateColumns: `repeat(1, 1fr)`,
            gap: "1rem",
            paddingY: "2rem",
          },
        }}
      >
        <ActionAreaCard
          image={claimdesk}
          title={"ClaimDesk"}
          content={
            "Claims management can be a cumbersome and error-prone process. Manual entry of claim details, inefficient workflows, and a lack of actionable insights lead to delays, increased costs, and missed opportunities for improvement."
          }
          shareURL={"/products/claim-desk"}
        />
        <ActionAreaCard
          image={docStore}
          title={"DocStore"}
          content={
            "Organizations often face significant challenges managing their documents. Files are scattered across multiple systems, tools, and formats, making it difficult to locate or share them efficiently. This leads to wasted time, decreased productivity, and frequent errors in document handling."
          }
          shareURL={"/products/doc-store"}
        />
        <ActionAreaCard
          image={deliveryDesk}
          title={"DeliveryDesk"}
          content={
            "Managing deliveries efficiently is a complex task. Delivery information and documents are often scattered across multiple systems, leading to confusion, delays, and increased compliance and payment turnaround times (TATs)."
          }
          shareURL={"/products/delivery-desk"}
        />
      </Box>
      <Footer />
    </ImageBackground>
  );
};

export default Products;
