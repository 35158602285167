import { useTheme } from "@emotion/react";
import { Box, Link as MuiLink } from "@mui/material";

import { GradientButton, OutlinedButton } from "components/button/buttons";
import NavBar from "components/navbar/NavBar";
import { sparkleSvg } from "components/svgIcons/svgIcons";
import { Link } from "react-router-dom";

const Hero = () => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, text, error, secondary } = palette;
  const { pxToRem } = functions;
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100vh",
        minHeight: "600px",
        overflow: "hidden",
      }}
    >
      <video
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
          objectFit: "cover",
        }}
        src="/videos/bgvideo.webm"
        autoPlay
        muted
        loop
      />
      <Box
        sx={{
          width: "90vw",
          height: "90vw",
          borderRadius: "100%",
          padding: "20px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          opacity: ".9",
          background: "linear-gradient(to bottom, white, #F0F5FF)",
              "@media (max-width: 800px)": {
                height: "110vh",
              },
        }}
      ></Box>

      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: "1",
          top: "0",
          left: "0",
        }}
      >
        <NavBar
          links={
            <>
              <Link to="/products">Products</Link>
              <Link to={"/blog"}>Blogs</Link>
            </>
          }
        />
        <Box
          sx={{
            height: "calc(100% - 85px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "1rem",
            width: "70%",
            margin: "auto",
            maxWidth: "998px",
            textAlign: "center",
            "@media (max-width: 1100px)": {
              width: "80%",
            },
            "@media (max-width: 800px)": {
              width: "90%",
            },
          }}
        >
          <Box
            sx={{
              fontSize: "4rem",
              lineHeight: "125%",
              letterSpacing: "-1px",
              fontWeight: "500",
              "@media (max-width: 1300px)": {
                fontSize: "3rem",
              },
              "@media (max-width: 800px)": {
                fontSize: "2rem",
              },
            }}
            className={"quicksand heading"}
          >
            <Box
              sx={{
                background: `-webkit-linear-gradient(45deg, ${primary.main}, ${secondary.main})`,
                backgroundClip: "text",
                textFillColor: "transparent",
              }}
            >
              Unify Your Logistics
            </Box>
            <Box>
            From Delivery to Claims.{" "}
              <span style={{ fontSize: "3rem" }}>
                {sparkleSvg(primary.main)}
              </span>
            </Box>
          </Box>
          <Box
            sx={{
              color: text.main,
              width: "55%",
              maxWidth: "577px",
              marginY: "2rem",
              "@media (max-width: 1100px)": {
                width: "65%",
              },
              "@media (max-width: 800px)": {
                width: "75%",
              },
              "@media (max-width: 500px)": {
                width: "85%",
              },
            }}
          >
            NeatProcess centralizes delivery data and documents from all sources, giving you full visibility and faster claims resolution.
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
              "@media (max-width: 500px)": {
                flexDirection: "column",
              },
            }}
          >
            <GradientButton
              name={"Discover our Products"}
              sx={{
                padding: ".5rem 2rem",
                color: white.main + " !important",
              }}
              endIcon={
                <span
                  class="material-symbols-outlined"
                  style={{ color: white.main }}
                >
                  east
                </span>
              }
              component={MuiLink}
              href="#products"
            />
            <OutlinedButton
              name={"Request a Demo"}
              sx={{
                padding: ".5rem 2rem",
              }}
              data-tally-open="mOAaPK"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Hero;
