import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    document.querySelector("html").scrollTo(0, 0);
    document.querySelector("html").style.scrollBehavior = "smooth";
  }, [location]);

  return null;
};

export default ScrollToTop;
