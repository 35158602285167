import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import NavBar from "components/navbar/NavBar";
import { Link } from "react-router-dom";
import { ImageBackground } from "components/background/background";
import Footer from "layouts/home/components/Footer";

const SecurityPolicy = () => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, text, error, secondary } = palette;
  const { pxToRem } = functions;

  return (
    <ImageBackground>
      <NavBar
        links={
          <>
            <Link to="/terms/privacy-policy">Privacy policy</Link>
            <Link to="/terms/security-policy">Security policy</Link>
            <Link to="/terms/gdpr-policy">GDPR</Link>
          </>
        }
      />
      <Box
        sx={{
          borderRadius: "20px",
          backgroundColor: white.main,
          padding: "2rem 4rem",
          width: "79%",
          margin: "auto",
          maxWidth: "990px",
          marginY: "2rem",
          backgroundColor: "#ffffff54",
          boxShadow: "0 4px 14px 0 #6A6BFF99",
          "@media (max-width: 700px)": {
            width: "89%",
            padding: "2rem",
          },
          "@media (max-width: 500px)": {
            width: "89%",
            padding: "1rem",
          },
        }}
      >
        <article id="f7e9ef40-d51e-402e-b454-554913cc3a53" class="page sans">
          <header>
            <h1 class="page-title">Security Policy</h1>
            <p class="page-description"></p>
          </header>
          <div class="page-body">
            <h1 id="f6ae09c2-d2ec-4337-a6ba-10752787ce98" class="">
              <strong>Security</strong>
            </h1>
            <p id="8486bb80-5579-4d55-b357-b041e49596f2" class="">
              Neatprocess provides Software as a Service(SaaS) products to users
              worldwide to solve their business problems. Security is a key
              component in our offerings, and is reflected in our people,
              process, and products. This page covers topics like data security,
              operational security, and physical security to explain how we
              offer security to our customers.
            </p>
            <p id="d8ac6501-93b9-4096-8483-12944bf05e8f" class="">
              Please note: Our softwares do not need any antivirus exclusion to
              run.
            </p>
            <h1 id="aa456374-35d6-4179-80a3-4b5c34f97652" class="">
              <strong>Overview</strong>
            </h1>
            <p id="7ebf2c34-d57c-4642-8ac6-bd60ddea992e" class="">
              Our security strategy involves the following components
            </p>
            <ul id="b2f6ff59-8fc5-4064-a5bd-83eacdcbe575" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>Organizational security</li>
            </ul>
            <ul id="788f3f40-f69b-4093-9d60-6d46c057c4ea" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>Physical security</li>
            </ul>
            <ul id="862020f1-d636-42cc-bad6-6ea2419b8587" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>Infrastructure security</li>
            </ul>
            <ul id="35a98118-bf75-4852-87a1-0a350547e20f" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>Data security</li>
            </ul>
            <ul id="3fe28cb3-3b80-4255-af28-8a1cbf14d6cc" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                Identity and access control
              </li>
            </ul>
            <ul id="457dfe1f-7325-4cd9-a47b-bd131cf3044b" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>Operational security</li>
            </ul>
            <ul id="6c0b968a-eafe-41e2-adb6-51167441ec10" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>Incident management</li>
            </ul>
            <ul id="9aacef52-1eb5-45fd-9e73-7c4546024bfc" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>Responsible disclosures</li>
            </ul>
            <ul id="cf17568d-d06a-4763-8d34-af1cf748e442" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>Vendor management</li>
            </ul>
            <ul id="19d4e17f-66e8-4047-aee3-7b6457e921a0" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                Customer controls for security
              </li>
            </ul>
            <h2 id="62366803-6ab2-4114-b15c-8802ab1219ac" class="">
              <strong>Organizational security</strong>
            </h2>
            <h3 id="6fe0c8ef-fb6d-4333-b56e-6c739acf0179" class="">
              <strong>Security Awareness</strong>
            </h3>
            <p id="23866ab0-9e46-4bc8-95d5-5ff7f55cd00f" class="">
              Each employee, when inducted, signs a confidentiality agreement
              and acceptable use policy, after which they undergo training in
              information security, privacy, and compliance.
            </p>
            <p id="f88d0433-5450-4d78-8ff8-8cddc8e0c860" class="">
              We educate our employees continually on information security,
              privacy, and compliance in our internal community where our
              employees check in regularly, to keep them updated regarding the
              security practices of the organization. We also host internal
              events to raise awareness and drive innovation in security and
              privacy.
            </p>
            <h3 id="a4aed0eb-bb4a-41c6-b0e8-7f3e7f81f7dc" class="">
              <strong>Endpoint security</strong>
            </h3>
            <p id="3a6ba060-07a1-4062-88b0-04aa41f85864" class="">
              We are in the transition of moving from BYOD to providing
              organization managed devices. Below mentioned are how both ways of
              accessing data are secure
            </p>
            <h3 id="4c17a59d-654c-4442-8cfd-06da2ac01a47" class="">
              <strong>BYOD setup:</strong>
            </h3>
            <ol
              type="1"
              id="4304b146-084a-4417-b76e-7cd71323e872"
              class="numbered-list"
              start="1"
            >
              <li>
                The BYOD devices are whitelisted for their MAC address and
                checked by our security team for potential malware.
              </li>
            </ol>
            <ol
              type="1"
              id="9786cdc3-3186-4751-a669-864620fd81fb"
              class="numbered-list"
              start="2"
            >
              <li>
                Devices list are regularly monitored by administrators and
                old/unused devices are blocked from using the workspace account.
              </li>
            </ol>
            <ol
              type="1"
              id="3df311ee-d491-4d9f-bd78-459459ba4ed8"
              class="numbered-list"
              start="3"
            >
              <li>
                Every employee will be receiving mail with different VPN setups
                to connect to different cloud environments.
              </li>
            </ol>
            <ol
              type="1"
              id="12cf4e1c-8ec6-436e-aa0b-00125f5a98ec"
              class="numbered-list"
              start="4"
            >
              <li>
                Production and Test cloud environments setups are separated, to
                have more access controls in place.
              </li>
            </ol>
            <ol
              type="1"
              id="8309db3b-97c4-4ec1-b8ad-cacced594827"
              class="numbered-list"
              start="5"
            >
              <li>
                Since the BYOD devices cannot be controlled much, all
                development is done on our cloud environment, where every
                employee with a BYOD device receives a logical division.
              </li>
            </ol>
            <ol
              type="1"
              id="e8118dc8-34fa-4824-a77a-caad7f047b2a"
              class="numbered-list"
              start="6"
            >
              <li>
                All types of development is done using SSH tunneling softwares
                along with the IDE’s to make sure data is secure.
              </li>
            </ol>
            <ol
              type="1"
              id="3fd885e3-fb66-47a9-911c-506b58d63dda"
              class="numbered-list"
              start="7"
            >
              <li>
                Employees won't be able to access data outside of these cloud
                environments.
              </li>
            </ol>
            <ol
              type="1"
              id="cf2586fa-f1c2-4f06-a769-1e8edba104eb"
              class="numbered-list"
              start="8"
            >
              <li>
                Employees owning the device will be made aware of their
                responsibilities, do’s and don'ts. We get a formal signature of
                employees in our COBC contract where we have clearly mentioned
                the responsibilities while using BYOD devices.
              </li>
            </ol>
            <h3 id="cc681244-5c03-4770-ab62-1731e59cf6ec" class="">
              <strong>Organizational workstation setup</strong>
            </h3>
            <ol
              type="1"
              id="6492ee03-aac6-47a4-9651-0f9d5ff32238"
              class="numbered-list"
              start="1"
            >
              <li>
                All workstations issued to employees run up-to-date OS versions
                and are configured with anti-virus software.
              </li>
            </ol>
            <ol
              type="1"
              id="2f86ff5e-2897-4183-b710-f80e7998082a"
              class="numbered-list"
              start="2"
            >
              <li>
                They are configured such that they comply with our standards for
                security, which require all workstations to be properly
                configured, patched, and be tracked and monitored by endpoint
                management solutions.
              </li>
            </ol>
            <ol
              type="1"
              id="e654b235-7810-4fca-a0fa-22ac5ba11413"
              class="numbered-list"
              start="3"
            >
              <li>
                These workstations are secure by default as they are configured
                to encrypt data at rest, have strong passwords, and get locked
                when they are idle.
              </li>
            </ol>
            <ol
              type="1"
              id="3830f294-9632-4cf5-89c8-48cfe3d788ec"
              class="numbered-list"
              start="4"
            >
              <li>
                Every employee will be receiving mail with different VPN setups
                to connect to different data sources.
              </li>
            </ol>
            <ol
              type="1"
              id="3b65e11e-6bd2-4bf8-86a0-0ae53bddf9ca"
              class="numbered-list"
              start="5"
            >
              <li>
                Data sources can only be accessed only when in a certain
                network, and all outside traffic is blocked.
              </li>
            </ol>
            <ol
              type="1"
              id="bc32587a-8acf-44cb-8667-a9d70f10207a"
              class="numbered-list"
              start="6"
            >
              <li>
                Production and Test VPN setups are separated, to have more
                access controls in place.
              </li>
            </ol>
            <ol
              type="1"
              id="96358b83-2535-4986-bd18-b5e918eb6003"
              class="numbered-list"
              start="7"
            >
              <li>
                Employees owning the device will be made aware of their
                responsibilities, do’s and don'ts. We get a formal signature of
                employees in our COBC contract where we have clearly mentioned
                the responsibilities while using organizational devices.
              </li>
            </ol>
            <h3 id="e8dbcaab-7f67-4da2-a3b3-f6daa8ec8d89" class="">
              <strong>Physical security: Remote Setup</strong>
            </h3>
            <ul id="65a4a1de-e3d5-4fca-9f25-7bfa4c2a4d1f" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>Access Control</strong>: Although we operate remotely,
                physical security measures are still relevant for controlling
                access to company resources.
              </li>
            </ul>
            <ul id="81567c51-0fa2-4c16-b317-e0e478142d3c" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>Remote Monitoring</strong>: We implement remote
                monitoring tools to ensure the security of endpoints and control
                access to our digital resources, maintaining a high level of
                security even in a remote work environment.
              </li>
            </ul>
            <h3 id="b7929201-0436-4b26-9b5e-d7e2453d48bb" class="">
              <strong>Infrastructure security:</strong>
            </h3>
            <h3 id="f869598f-1766-43e4-a355-72df96e5b06e" class="">
              <strong>Network security</strong>
            </h3>
            <p id="ed73c2d6-eb8f-432f-bb6a-e94c052aeb37" class="">
              Our network security and monitoring techniques are designed to
              provide multiple layers of protection and defense.
            </p>
            <p id="1a8ea543-ee82-42e8-86b4-69d852323287" class="">
              <strong>Web Application Firewall (WAF)</strong>:
            </p>
            <p id="3610db6c-2258-4dca-a633-3680bc8beaa8" class="">
              We use AWS WAF firewalls to prevent our network from unauthorized
              access and undesirable traffic. Our systems are segmented into
              separate networks to protect sensitive data.
            </p>
            <p id="85b153b0-80df-404c-b3fa-740c1f4d707e" class="">
              <strong>SSL/TLS</strong>
            </p>
            <p id="88672847-d151-475c-a2f2-59406a73fd7a" class="">
              Load balancers are configured to handle SSL/TLS, ensuring that all
              data in transit is encrypted and secure communication is mandated.
            </p>
            <p id="38527266-86f2-4184-acff-1c8469c7bc59" class="">
              <strong>Device Authentication</strong>
            </p>
            <p id="ec224d05-4132-451d-bece-c260592970a2" class="">
              Only authorized devices are allowed to connect to the network.
              This is enforced through device authentication mechanisms though
              our workspace accounts provider.
            </p>
            <p id="61f04857-6770-4fcf-9b25-6f561071abe4" class="">
              <strong>MAC Address Filtering</strong>:
            </p>
            <p id="02bc5502-5d0c-4e57-b1b8-2e483db472e2" class="">
              Network access is restricted to devices with specific MAC
              addresses that have been pre-approved and whitelisted though our
              workspace accounts provider and our VPN manager.
            </p>
            <p id="ac67dcc2-00a6-484e-ab05-dd4786581d6d" class="">
              <strong>Development environments:</strong>
            </p>
            <p id="ef455a30-0aea-40de-ad8c-21e58de05793" class="">
              Systems supporting testing and development activities are hosted
              in a separate network from systems supporting production
              infrastructure.
            </p>
            <p id="a8a7b227-9332-45fd-b678-887df2d7f383" class="">
              <strong>DDoS prevention</strong>
            </p>
            <p id="2406f2c7-b271-4650-9584-7cb131288e71" class="">
              We use AWS shield to prevent DDoS attacks on our servers. This
              offers multiple DDoS mitigation capabilities to prevent
              disruptions caused by bad traffic, while allowing good traffic
              through. This keeps our websites, applications, and APIs highly
              available and performing.
            </p>
            <h3 id="1962d8a3-2223-4215-b141-195d166487a6" class="">
              <strong>Email Security</strong>
            </h3>
            <p id="774fbc32-0d1b-4af4-b896-363f472a7602" class="">
              All connections to our servers use TLS 1.2/1.3 encryption with
              strong ciphers, ensuring secure transmission of data.Advanced spam
              and malware filters block malicious emails and protect users from
              email-based threats.
            </p>
            <h3 id="9d2719a7-bacf-4dbc-b33d-e5a1c685d371" class="">
              <strong>Intrusion detection and prevention</strong>
            </h3>
            <p id="491f5858-d532-49ce-9ecc-ac9fc433a972" class="">
              We use different AWS services to monitor. We have alarms in place
              for different triggers helping us get notified of potential
              problems.
            </p>
            <p id="7c59dd56-ae20-43db-86dd-600b5b76d987" class="">
              At the application layer, we have AWS WAF which operates on both
              whitelist and blacklist rules.
            </p>
            <h3 id="de63971b-56bc-44fb-9b3d-cd556ec5019a" class="">
              <strong>Data security</strong>
            </h3>
            <h3 id="01fb55ee-a58c-45f1-8ea2-7264fd21a997" class="">
              <strong>Secure by design</strong>
            </h3>
            <p id="bb4b6364-9cc0-4a9b-b1b9-7d1533a5f86c" class="">
              Every change and new feature is authorized before implementation
              into production. Our Software Development Life Cycle (SDLC)
              mandates adherence to secure coding guidelines, as well as
              screening of code changes for potential security issues with our
              code analyser tools, vulnerability scanners,applying patches and
              updates to vulnerable systems in a timely manner and manual review
              processes.
            </p>
            <p id="a03995d3-e0ef-495f-8b05-4592a2feb8c1" class="">
              We use AWS WAF and AWS shield to protect us from different
              attacks, such as SQL injection,Cross site scripting and
              application layer DDOS attacks.
            </p>
            <h3 id="5924dc9c-6a1d-4ee0-8507-355ee9ea946c" class="">
              <strong>Data isolation</strong>
            </h3>
            <p id="f639d8fe-7109-478f-b249-18d1e99527bd" class="">
              Our framework distributes and maintains the cloud space for our
              customers. Each customer's service data is logically separated
              from other customers' data using a set of secure protocols in the
              framework. This ensures that no customer's service data becomes
              accessible to another customer.
            </p>
            <p id="f6e7214d-753f-491b-a0dc-3559ada2d229" class="">
              The service data is stored on our servers when you use our
              services. Your data is owned by you, and not by Us. We do not
              share this data with any third-party without your consent.
            </p>
            <h3 id="87ba1325-cdab-4781-9d78-159254111a5c" class="">
              <strong>Encryption</strong>
            </h3>
            <h3 id="092ef23e-0adb-4e63-8b45-5e782abb7eb0" class="">
              <strong>In transit</strong>:
            </h3>
            <p id="57a9a171-e0bc-4756-bab0-3d7a6a579415" class="">
              All customer data transmitted to our servers over public networks
              is protected using strong encryption protocols. We mandate all
              connections to our servers to use Transport Layer Security (TLS
              1.2/1.3) encryption with strong ciphers, for all connections
              including web access,API access,our mobile apps, and IMAP/POP/SMTP
              email client access. This ensures a secure connection by allowing
              the authentication of both parties involved in the connection, and
              by encrypting data to be transferred. Additionally for email, our
              services leverage opportunistic TLS by default. TLS encrypts and
              delivers email securely, mitigating eavesdropping between mail
              servers where peer services support this protocol.
            </p>
            <h3 id="31b87e24-cd45-42db-bb32-4ccd1908fc45" class="">
              <strong>At rest:</strong>
            </h3>
            <p id="3d1e6144-3a5f-4576-ad5b-8799c6df39d8" class="">
              Sensitive customer data at rest is encrypted using 256-bit
              Advanced Encryption Standard (AES). The data that is encrypted at
              rest varies with the services you opt for. We use AWS-Secrets to
              manage our Keys. We provide additional layers of security by
              encrypting the data encryption keys using master keys.
            </p>
            <p id="d1eb504f-e926-4248-a0f2-23912a528a5a" class="">
              Keys for different purposes are stored separately, to reduce
              breach risk.
            </p>
            <h3 id="5714f5ae-b809-461b-b121-95dcba6a758c" class="">
              <strong>No Physical Documents</strong>
            </h3>
            <p id="c48fee76-213d-4ffb-9c19-ab96c0931890" class="">
              <strong>Digital-Only Policy</strong>: Our organization operates
              with a digital-only policy, meaning no physical documents
              containing sensitive data are used or stored. All sensitive
              information is managed and processed electronically.
            </p>
            <p id="b733f36b-ff28-45d5-a0aa-f8a0c4c6ff98" class="">
              <strong>Secure Document Management</strong>: Digital documents are
              stored in secure, access-controlled environments. Sensitive data
              within these documents is encrypted to prevent unauthorized
              access.
            </p>
            <h3 id="9c83726b-3233-4c58-899b-011b176fa8ad" class="">
              <strong>Data retention and disposal</strong>
            </h3>
            <p id="42f2943c-d869-4fca-bb91-e71f615e541b" class="">
              Our goal is to keep your personal information for as short period
              as possible to achieve the purpose for which your personal
              information is collected. We will retain your personal information
              as needed to fulfill the purposes for which it was collected. We
              may retain your personal information as needed to provide you
              services, comply with our business requirements and legal
              obligations, resolve disputes and enforce our rights and
              agreements. Please refer to privacy policy for more information.
            </p>
            <h3 id="3f82c546-2ac1-4774-8c35-77cf893aa9b2" class="">
              <strong>Identity and Access control</strong>
            </h3>
            <h3 id="2368e064-cd79-4191-95d0-85b45aed0151" class="">
              <strong>Strong Password Policy</strong>
            </h3>
            <ul id="3a58f58e-9237-416f-ac16-1985bf13d2db" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>Password Requirements</strong>: All passwords must be a
                minimum of 8 characters long and include a combination of
                uppercase letters, lowercase letters, numbers, and special
                characters.
              </li>
            </ul>
            <ul id="2a8f8310-0deb-448c-9847-63f18f3f43c1" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>Password Expiration</strong>: Passwords must be changed
                every 90 days.
              </li>
            </ul>
            <ul id="d439914a-1895-4ed5-8517-518466da9878" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>Account Lockout</strong>: Accounts will be locked out
                after 10 failed login attempts. The lockout can be revoked only
                by admins.
              </li>
            </ul>
            <ul id="53b3efa2-66b5-401c-be2f-745c3598cba6" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>Password Storage</strong>: Passwords must be hashed
                using a strong, non-reversible hashing algorithm such as bcrypt
                or SHA-256, each password is combined with a unique salt and
                stored securely.
              </li>
            </ul>
            <ul id="76002494-81ab-42c5-ba19-96f952b25ffc" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>Two-Factor Authentication (2FA)</strong>: Users must
                enable two-factor authentication to add an additional layer of
                security.
              </li>
            </ul>
            <ul id="0950370f-afc6-44d2-b596-6f836d840883" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>Password Reset</strong>: Password reset requests will
                require identity verification through email or SMS.
              </li>
            </ul>
            <ul id="4cf6aeca-8a68-4b71-a665-957ce712f233" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>Sensitive Accounts password Update Frequency</strong>:
                Passwords for sensitive accounts must be updated every 60 days
                to reduce the risk of unauthorized access.
              </li>
            </ul>
            <h3 id="efde6d87-bcb7-4529-a8dc-f13ce6463444" class="">
              <strong>
                User Access Assignment According to Least Privilege
              </strong>
            </h3>
            <ul id="d2e1b414-c202-463c-a093-8f6337aaffc2" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>Default Access Level</strong>: By default, all users are
                assigned non-admin access to systems and data. This ensures that
                users start with the minimum necessary permissions required to
                perform their job functions.
              </li>
            </ul>
            <ul id="06ce1ef7-9093-4ccd-9b72-26ee158c7a46" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>Admin Access Assignment</strong>: Administrative access
                is granted only to those users whose job functions explicitly
                require such access. Requests for admin access must go through a
                formal approval process (currently on emails) and be justified
                by the user's role and responsibilities.
              </li>
            </ul>
            <ul id="61ff2983-586e-44ab-b895-237a9d650518" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>Role-Based Access Control (RBAC)</strong>: Access to
                systems and data is assigned based on the user's role within the
                organization. Each role has predefined access permissions that
                align with the minimum requirements to perform job functions.
              </li>
            </ul>
            <ul id="302ef73c-1354-4788-b0b0-3f0bb2f7cf60" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>Access Reviews</strong>: Regular reviews of user access
                rights are conducted to ensure compliance with the least
                privilege principle. Access permissions are adjusted as needed
                to reflect changes in job responsibilities or organizational
                structure.
              </li>
            </ul>
            <ul id="cfbf09ad-349a-42ae-a19c-425ab347d4f5" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>Access Requests and Approvals</strong>: Any requests for
                additional access must go through a formal approval process
                (currently a mail is sent, requesting for access). The request
                must be justified, and the approval must come from the relevant
                authority, typically the user's manager or the IT security team.
              </li>
            </ul>
            <ul id="41747097-14c7-47b8-b2b2-1ae685ec1852" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>Monitoring and Auditing</strong>: Access logs are
                continuously monitored, and regular audits are conducted to
                ensure that access permissions are in line with the least
                privilege principle. Any anomalies or unauthorized access
                attempts are promptly investigated.
              </li>
            </ul>
            <ul id="9f586ef2-105f-4528-bac0-5859aaf49cd6" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>Separation of Duties</strong>: Where feasible,
                responsibilities are divided among multiple users to reduce the
                risk of fraud or unauthorized access. Critical tasks require the
                collaboration of two or more users to complete.
              </li>
            </ul>
            <ul id="8de5bcb6-2524-4015-a8c5-e4fb4059b216" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>Access Deactivation</strong>: Access rights are
                immediately revoked for employees who leave the organization or
                change roles. A formal offboarding process ensures that all
                access is properly deactivated. (starts with the email from
                Authorized individuals like managers to the administrator)
              </li>
            </ul>
            <ul id="635dfea3-d5fc-458d-8e6c-5002b743f1b3" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                <strong>Training and Awareness</strong>: Users granted
                administrative access receive additional training on security
                practices and the responsibilities that come with elevated
                access.
              </li>
            </ul>
            <h3 id="2bd10e12-3c09-40a3-a675-0259b00657fe" class="">
              <strong>Operational security</strong>
            </h3>
            <h3 id="d94ac7da-74d5-4396-b378-6691eefa12cb" class="">
              <strong>Logging and Monitoring</strong>
            </h3>
            <h3 id="f7b1758e-853e-40e8-933a-6e2865ca802e" class="">
              <strong>Activity Logging within our Organization Systems:</strong>
            </h3>
            <p id="37be146b-80fa-4d1b-add0-600f11d41a45" class="">
              All user activities in our organizational systems are logged. This
              includes login attempts, access to sensitive data, changes made to
              system configurations, and any other significant actions. Logs
              capture details such as the user ID, timestamp, source IP address,
              and a description of the activity.
            </p>
            <h3 id="ca6d3930-6f96-4b61-aecd-623b3b602469" class="">
              <strong>Activity Logging in Business applications</strong>:
            </h3>
            <p id="baf737a2-16ca-4624-82e9-6837b063f254" class="">
              All user activities in our Business applications systems are
              logged. This includes access to clients data, modifications, and
              other critical actions.
            </p>
            <p id="77cbd776-1df9-415f-ae31-20f922db89fa" class="">
              Logs capture the same level of detail as organizational systems,
              including user ID, timestamp, source IP address, and a description
              of the activity.
            </p>
            <h3 id="cf5afd93-1d40-49fa-a593-f4ab04d9d2a3" class="">
              <strong>Activity Monitoring</strong>:
            </h3>
            <p id="5e77fce9-f662-4709-bf7c-8d0d40070735" class="">
              All user activities in our organizational systems and business
              applications are continuously monitored under. This includes
              monitoring login attempts, access to sensitive data, changes made
              to system configurations, and any other significant actions.
            </p>
            <p id="f3f9ed49-e976-46a9-a6f7-32b501922882" class="">
              Real-time alerts are configured to notify relevant personnel of
              any suspicious or unauthorized activities.
            </p>
            <h3 id="e84ee68d-8d4c-4d72-a0e7-bb31d9507590" class="">
              <strong>Vulnerability management</strong>
            </h3>
            <p id="6d0ffc99-dd15-4d79-8406-f1af2dac8fc8" class="">
              We have a dedicated vulnerability management process that actively
              scans for security threats using a combination of certified
              third-party scanning tools and in-house tools, and with automated
              and manual penetration testing efforts.
            </p>
            <p id="d7debb93-1332-40ff-b914-b0c94487a2b3" class="">
              Once we identify a vulnerability requiring remediation, it is
              logged, prioritized according to the severity, and assigned to an
              owner. We further identify the associated risks and track the
              vulnerability until it is closed by either patching the vulnerable
              systems or applying relevant controls.
            </p>
            <h3 id="0fab62b1-81dd-4cd2-9498-922bea342d95" class="">
              <strong>Backup</strong>
            </h3>
            <p id="36429b5e-6866-440d-a5e8-e95648c09f4d" class="">
              <strong>Backup Frequency</strong>:
            </p>
            <ul id="0669643d-3b00-40fe-a4ae-9ed581b63f83" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                All business applications and data are backed up daily to ensure
                minimal data loss in case of an incident. Incremental backups
                are performed daily, with full backups conducted weekly.We store
                them in tar.gz format.
              </li>
            </ul>
            <ul id="59c6093f-ddd6-4ba2-9c49-a3f1b5cd8d82" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                All Cloud environment backups are done monthly[used to connect
                by BYOD devices]
              </li>
            </ul>
            <p id="636acb8c-0f08-4436-b567-ae9c03ee6a0f" class="">
              <strong>Backup Location</strong>: Backups are stored in a
              different system than the original data to provide a safeguard
              against data loss due to system failures or disasters. These
              backup systems are geographically separated to ensure data
              availability in case of local disasters.
            </p>
            <p id="3cfd94a4-237c-4184-98a0-10cacb5a2f1d" class="">
              <strong>Encryption</strong>: All backups are encrypted using
              industry-standard encryption protocols to ensure the
              confidentiality and integrity of the backed-up data. We use
              AES-256 encryption to secure our backup data both in transit and
              at rest.
            </p>
            <p id="2b1311e9-3cb9-4c01-9c2d-bdea869c2987" class="">
              <strong>Retention Policy</strong>: Backups are retained for a
              period of three(3) months. After this period, backups are securely
              deleted to ensure compliance with data retention policies and to
              free up storage space.
            </p>
            <p id="474ab242-0ebd-44ec-ba21-1af9f95a7cb6" class="">
              <strong>Restoration Testing</strong>: Regular restoration tests
              are conducted to ensure the integrity and usability of backups.
              These tests are performed monthly to verify that backups can be
              successfully restored and that data is intact and accessible.
            </p>
            <h3 id="e9556440-1f97-4996-be99-e9e675f0dbf9" class="">
              <strong>Disaster recovery and business continuity</strong>
            </h3>
            <p id="7fd304ea-60ae-4302-a645-e059ce4d4b3c" class="">
              <strong>Server Failover</strong>: In the event of a server
              failure, automated failover mechanisms switch operations to a
              backup server without manual intervention. This minimizes downtime
              and ensures continuity of service.
            </p>
            <p id="5992add5-2bf0-4d5f-b1c5-3798a308acf3" class="">
              <strong>Region Failover</strong>: In the event of a region
              failure, the administrator gets notified and spins up another
              region which gives a maximum downtime of 15 mins.
            </p>
            <p id="4bc85fc6-db50-42db-88f8-39a124f7af12" class="">
              <strong>Commitment to Uptime</strong>: We are committed to
              providing a high level of availability as defined in our SLAs. We
              aim for a minimum of 99.9% uptime for all critical services.
            </p>
            <p id="57cce015-67c6-4c3a-8736-c728c21bc1ae" class="">
              <strong>Monitoring and Reporting</strong>: Continuous monitoring
              of system performance and uptime is conducted. Regular reports are
              generated to track compliance with SLAs and identify areas for
              improvement.
            </p>
            <h3 id="d1be737e-a773-4110-99d4-c1462996ff34" class="">
              <strong>Incident Management</strong>
            </h3>
            <h3 id="8ab4ce65-cff6-44a8-88a3-d95098d4fc51" class="">
              <strong>Reporting</strong>
            </h3>
            <p id="cbf5d24a-0178-4405-b980-a50e1a939946" class="">
              We have assigned a dedicated incident manager. We notify you of
              the incidents in our environment that apply to you, along with
              suitable actions that you may need to take. We track and close the
              incidents with appropriate corrective actions. Whenever
              applicable, we will identify, collect, acquire and provide you
              with necessary evidence in the form of application and audit logs
              regarding incidents that apply to you. Furthermore, we implement
              controls to prevent recurrence of similar situations.
            </p>
            <p id="349acff0-9af7-4023-afa4-eb5c699d93d1" class="">
              We respond to the security or privacy incidents you report to us
              through security@neatprocess.com, with high priority. For general
              incidents, we will notify users through our blogs, forums, and
              social media. For incidents specific to an individual user or an
              organization, we will notify the concerned party through email
              (using their primary email address of the Organisation
              administrator registered with us).
            </p>
            <h3 id="be9ab3da-b241-4376-b349-0533a342951b" class="">
              <strong>Breach notification</strong>
            </h3>
            <p id="5ee9dbd6-9d3e-4368-b71c-09b4d90824ff" class="">
              As data controllers, we notify the concerned Data Protection
              Authority of a breach within 72 hours after we become aware of it,
              according to the General Data Protection Regulation (GDPR).
              Depending on specific requirements, we notify the customers too,
              when necessary. As data processors, we inform the concerned data
              controllers without undue delay.
            </p>
            <h3 id="0f0e9f6a-398e-4c86-a2c1-bc62830c9196" class="">
              <strong>Responsible Disclosures</strong>
            </h3>
            <p id="30258800-82d6-4784-bf3b-bf199c661c58" class="">
              A vulnerability reporting program "Bug Bounty", to reach the
              community of researchers, is being introduced to implement
              appropriate solutions for the reported vulnerabilities.
            </p>
            <p id="f7685a2a-c3ab-45df-9259-f2c499fcd2e4" class="">
              If you happen to find any, please submit the issues at
              security@neatprocess.com.
            </p>
            <h3 id="67a4e535-1c68-456d-b06a-4ae1b1956efb" class="">
              <strong>Vendor and Third-party supplier management</strong>
            </h3>
            <p id="f27820f4-e9b5-40f4-a477-4421d6b225aa" class="">
              We evaluate and qualify our vendors based on our vendor management
              policy. We onboard new vendors after understanding their processes
              for delivering us service, and performing risk assessments. We
              take appropriate steps to ensure our security stance is maintained
              by establishing agreements that require the vendors to adhere to
              confidentiality, availability, and integrity commitments we have
              made to our customers. We monitor the effective operation of the
              organization’s process and security measures by conducting
              periodic reviews of their controls.
            </p>
            <h3 id="b5c1192c-fb03-479c-9d5a-2260732d7586" class="">
              <strong>Customer controls for security</strong>
            </h3>
            <p id="54a04a51-c012-416a-a7f3-f3f6afb4b535" class="">
              So far, we have discussed what we do to offer security on various
              fronts to our customers. Here are the things that you as a
              customer can do to ensure security from your end:
            </p>
            <ul id="ab4cd0ee-7165-40fa-8b01-67a70ca29660" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                Choose a unique, strong password and protect it.
              </li>
            </ul>
            <ul id="bbe59860-2b47-49e4-b38d-b4819e45fa96" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                Use multi-factor authentication
              </li>
            </ul>
            <ul id="6776ced4-6429-4a05-980a-08a9f038b10a" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                Use the latest browser versions, mobile OS and updated mobile
                applications to ensure they are patched against vulnerabilities
                and to use latest security features
              </li>
            </ul>
            <ul id="379e4b1d-447a-483e-a393-30ad20452643" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                Exercise reasonable precautions while sharing data from our
                cloud environment.
              </li>
            </ul>
            <ul id="f4bca81c-4ac8-44a5-afbb-ba0a349268e7" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                Monitor devices linked to your account, active web sessions, and
                third-party access to spot anomalies in activities on your
                account, and manage roles and privileges to your account.
              </li>
            </ul>
            <ul id="30c228ba-7806-4859-ba7b-418c6df73f3e" class="bulleted-list">
              <li style={{ listStyleType: "disc" }}>
                Be aware of phishing and malware threats by looking out for
                unfamiliar emails, websites, and links that may exploit your
                sensitive information by impersonating Neatprocess or other
                services you trust.
              </li>
            </ul>
            <p id="37cf7f9b-e350-43c5-9f6b-232990f985ab" class="">
              **Brand Ownership "Neatprocess" is a brand owned by Kriyarth
              Technologies Private Limited. All references to "Neatprocess"
              within this Policy and other company documents refer to the brand,
              products, and services under the ownership and operation of
              Kriyarth Technologies Private Limited.**
            </p>
          </div>
        </article>
      </Box>
      <Footer />
    </ImageBackground>
  );
};

export default SecurityPolicy;
